import React, { Component } from "react";
import { idEmpresa, PDF, aparienciaFirma } from "../../constans/constants";
import {
  updateTipoDocumento, setTipoDocumento,
  getDatosFormulario, getDocumento, updateXsdFile
}
  from "../tiposDocumento/TipoDocumentoService";
import AparienciaFirma from "./AparienciaFirma";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { validateForm } from "../../constans/validationUtils";
import { Container, Form, Modal, Row, Col, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";

class NuevoTipoDocumento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEmpresa: idEmpresa(),
      nombreDocumento: "",
      descripcion: "",
      formatoArchivo: "",
      formatoMetadato: "",
      origen: "",
      carpetaPorDefecto: "",
      carpetaPorDefectoActive: false,
      archivoXls: null,
      archivoXlsActive: false,
      isFirmaEmpresa: false,
      isFirmaColaborador: false,
      acciones: "",
      employeeComment: false,
      tipoEnvio: "",
      formatosImportacion: [],
      accionesEmpleado: [],
      origenLote: [],
      enterpSignatureFormat: "",
      employeeSignatureFormat: "",
      tipoEnvioMtess: [
        {
          key: 1,
          value: "LOTE_MANUAL",
          descripcion: "Por lote cerrado, previa confirmacion manual",
        },
      ],
      errors: {
        nombreDocumento: null,
        descripcion: null,
        formatoArchivo: null,
        metaFormato: null,
        origen: null,
        carpetaPorDefecto: null,
        acciones: null,
        tipoEnvio: null
      },
      firmas: [],
      mensaje: "",
      severity: "",
      mostrarMensajeFlash: false,
      openPantallaCarga: false,
      cronJobActive: false,
      isAparienciaFirma: true,
      remindDaysAfterPublish: 0,
      remindFrecuencyDays: 0,
      remindFrecuencyHours: 0,
      remindFrecuencyMinutes: 0,
      validated: false,
      editar: false
    };
  }

  reglasValidacionCampos = {
    nombreDocumento: [
      { required: true, message: 'Ingrese un nombre para el documento' },
    ],
    descripcion: [
      { required: true, message: 'Ingrese una descripcion para el documento' },
    ],
    formatoArchivo: [
      { required: true, message: 'Elija un formato de importacion' },
    ],
    metaFormato: [
      { required: true, message: 'Debe agregar un formato de nombre para los documentos' },
    ],
    origen: [
      { required: true, message: 'Elija un punto de origen para cargar lotes' },
    ],
    carpetaPorDefecto: [
      {
        customValidator: (value, formData) => {
          if (!this.state.carpetaPorDefectoActive)
            return true
          else
            return value.length > 0
        }, message: 'Debe ingresar una ruta de archivos valida'
      },
    ],
    acciones: [
      { required: true, message: 'Elija que acciones ejecutara el colaborador al recibir el documento' },
    ],
    tipoEnvio: [
      { required: true, message: 'Elija como se enviaran los documentos al MTESS' },
    ]
  }

  async componentDidMount() {

    getDatosFormulario().then((res) => {
      this.setState({
        formatosImportacion: res.formatoImp,
        accionesEmpleado: res.tipFirmaEmpleado,
        origenLote: res.origenLote,
        
      });
    })

    if (this.props.type === "edit") {
      getDocumento(this.props.id).then((res) => {
        if (res.error != null) {
          this.setState({
            nombreDocumento: res.datos.nombre,
            descripcion: res.datos.descripcion,
            formatoArchivo: res.datos.formato.id,
            metaFormato: res.datos.metaFromato,
            origen: res.datos.origenLote.id,
            acciones: res.datos.firmaEmpleado.id,
            isFirmaEmpresa: res.datos.firmaEmpresa,
            isFirmaColaborador: res.datos.firmaEmpleado.nombre.toLowerCase().includes("firma"),
            employeeComment: res.datos.employeeComment,
            employeeSignatureFormat: res.datos.formatoFirmaEmpleado,
            enterpSignatureFormat: res.datos.formatoFirmaEmpresa,
            tipoEnvio: res.datos.tipoEnvioMtess,
            cronJobActive: res.datos.cronJobActive,
            remindDaysAfterPublish: res.datos.remindDaysAfterPublish,
            remindFrecuencyDays: res.datos.remindFrecuencyDays,
            remindFrecuencyHours: res.datos.remindFrecuencyHours,
            remindFrecuencyMinutes: res.datos.remindFrecuencyMinutes,
            archivoXlsActive: res.datos.formato.extension == 'XML',
            carpetaPorDefectoActive: res.datos.carpeta.length > 0,
            carpetaPorDefecto: res.datos.carpeta,
            editar: true
          });
        } else {
          this.setState({
            mensaje: res.mensaje,
            severity: "danger"
          })
        }

      });
    }
  }

  handleDialog = () => {
    let value = !this.state.dialogIsOpen
    this.setState({
      dialogIsOpen: value,
      isAparienciaFirma: true,

    });
  };

  handleRecordatorio = (event) => {
    this.setState({
      cronJobActive: event.target.checked
    });
  }

  handleChecked = (event) => {
    let name = event.target.name;
    let val = event.target.checked;
    if (!val) {
      this.setState({
        [name]: val,
        remindDaysAfterPublish: 0,
        remindFrecuencyDays: 0,
        remindFrecuencyHours: 0,
        remindFrecuencyMinutes: 0
      });
    } else {
      this.setState({
        [name]: val,
      });
    }
  }

  onDrop = (files) => {
    this.setState({
      archivoXls: files[0],
    })
  }

  handleInput = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    switch (name) {
      case "formatoArchivo":
        if (val == "1") this.setState({ archivoXlsActive: true });
        else this.setState({ archivoXlsActive: false });
        break;
      case "origen":
        if (val == "1") {
          this.setState({
            carpetaPorDefectoActive: true,
          });
        } else {
          this.setState({
            carpetaPorDefectoActive: false,
          });
        }
        break;
      case "acciones": {
        let isFirmaColaborador = false;
        this.state.accionesEmpleado.forEach((e) => {
          if (e.id == val)
            isFirmaColaborador = e.nombre.includes("Firma")
        })
        this.setState({ isFirmaColaborador: isFirmaColaborador })
        break;
      }
      case "isFirmaEmpresa":
        val = e.target.value === 'true';
        break;
      
    }
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: null
      },
      [name]: val,
    }))
  };


  botonAprienciaFirma = () => {
    let valor = ((this.state.isFirmaColaborador || this.state.isFirmaEmpresa) && this.state.formatoArchivo == "2");
    return valor;

  };
  signEmpresa = (sign) => {
    this.setState({ enterpSignatureFormat: sign })
  }
  signEmpleado = (sign) => {
    this.setState({ employeeSignatureFormat: sign })
  }


  handleClose = (mensaje, severity) => {
    this.props.handleClose(mensaje, severity);
  };

  guardarTipoDocumento = (data) => {
    this.setState({ openPantallaCarga: true });
    if (this.props.type == "edit") {
      data.id = this.props.id;
      updateTipoDocumento(data).then(res => {
        if (!res.error) {
          if (this.state.archivoXls != null) {
            let id = res.datos.id;
            let XSLFile = new FormData();
            XSLFile.append("file", this.state.archivoXls);
            updateXsdFile(XSLFile, id).then(response => {
              if (!response.error)
                this.handleClose(res.mensaje + response.mensaje, "success")
              else
                this.handleClose(res.mensaje + response.mensaje, "danger")
            })
          }
          this.handleClose(res.mensaje, "success")
        } else {
          this.setState({
            mostrarMensajeFlash: true,
            mensaje: res.mensaje,
            severity: "danger",
            openPantallaCarga: false
          })
        }
      })

    } else {
      setTipoDocumento(data).then(res => {
        if (!res.error) {
          if (this.state.archivoXls != null) {
            let id = res.datos.id;
            let XSLFile = new FormData();
            XSLFile.append("file", this.state.archivoXls);
            updateXsdFile(XSLFile, id).then(response => {
              if (!response.error)
                this.handleClose(res.mensaje + response.mensaje, "success")
              else
                this.handleClose(res.mensaje + response.mensaje, "danger")
            })
          }
          this.handleClose(res.mensaje, "success")
        } else {
          try {
            const errores = JSON.parse(res.mensaje)
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                nombreDocumento: errores.name
              },
              openPantallaCarga: false
            }))
          } catch (e) {
            this.setState({
              mostrarMensajeFlash: true,
              mensaje: res.mensaje,
              severity: "danger",
              openPantallaCarga: false
            })
            this.handleClose(true)
          }
        }
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(this.state, this.reglasValidacionCampos);
    if (isValid) {
      let data = {
        id: "",
        name: this.state.nombreDocumento,
        description: this.state.descripcion,
        defaultFolder: this.state.carpetaPorDefecto,
        metaFormat: this.state.metaFormato,
        enterpSignature: this.state.isFirmaEmpresa,
        employeeSignatureId: this.state.acciones,
        formatId: this.state.formatoArchivo,
        bacthSourceId: this.state.origen,
        empresaId: idEmpresa(),
        enterpSignatureFormat: this.state.enterpSignatureFormat,
        employeeSignatureFormat: this.state.employeeSignatureFormat,
        seTransmite: 0,
        employeeComment: this.state.employeeComment,
        cronJobActive: this.state.cronJobActive,
        remindDaysAfterPublish: this.state.remindDaysAfterPublish,
        remindFrecuencyDays: this.state.remindFrecuencyDays,
        remindFrecuencyHours: this.state.remindFrecuencyHours,
        remindFrecuencyMinutes: this.state.remindFrecuencyMinutes,
        keystoreId: "",
        tipoEnvioMtess: this.state.tipoEnvio,
      };

      if (data.formatId == PDF && data.enterpSignatureFormat == "" && data.employeeSignatureFormat == "" && aparienciaFirma) {
        this.setState({ isAparienciaFirma: false });
        this.guardarTipoDocumento(data)
      } else {
        this.guardarTipoDocumento(data)
      }
    } else {
      this.setState({ errors: errors })
    }
  };

  render() {
    return (
      <Container>
        <Modal
          fullscreen
          show={this.state.dialogIsOpen}
          onHide={this.handleDialog}
          backdrop="static"
          keyboard="false">
          <AparienciaFirma
            isFirmaEmpresa={this.state.isFirmaEmpresa}
            isFirmaColaborador={this.state.isFirmaColaborador}
            handleClose={this.handleDialog}
            firmaEmpresa={this.signEmpresa}
            firmaEmpleado={this.signEmpleado}
            aparienciaEmpresa={this.state.enterpSignatureFormat}
            aparienciaEmpleado={this.state.employeeSignatureFormat}
            disabledInput={this.state.disabledInput}
            editar={this.state.editar}
          />
        </Modal>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col xs={12} md={6}>
                  <p className="form-date">
                    {" "}
                    <strong>Datos basicos</strong>
                  </p>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="nombreDocumento"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.nombreDocumento}
                      isInvalid={!!this.state.errors.nombreDocumento} />

                    <Form.Label className="md-label"> Nombre tipo documento </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p><small>{this.state.errors.nombreDocumento}</small></p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <Form.Control
                      className="form-control md-form-control"
                      as="textarea"
                      name="descripcion"
                      required
                      placeholder=" "
                      rows="2"
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                      isInvalid={!!this.state.errors.descripcion} />

                    <Form.Label className="md-label"> Descripcion </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p><small>{this.state.errors.descripcion}</small></p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <p className="form-date">
                    {" "}
                    <strong>Opciones de importacion</strong>
                  </p>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <Form.Control
                      as='select'
                      className="form-control md-select"
                      name="formatoArchivo"
                      onChange={this.handleInput}
                      value={this.state.formatoArchivo}
                      isInvalid={!!this.state.errors.formatoArchivo}>

                      <option key="" value="">Seleccione un formato</option>

                      {this.state.formatosImportacion.map((elemento) => (
                        <option key={elemento.codigo} value={elemento.id}>
                          {elemento.extension}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Label className="md-label"> Formato de importacion </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <p><small>{this.state.errors.formatoArchivo}</small></p>
                    </Form.Control.Feedback>
                  </Form.Group>

                  {this.state.archivoXlsActive &&
                    <Form.Group className="form-group md-input">
                      <Row xs="auto">
                        <Col>
                          <a className="btn btn-primary-outline">
                            <Dropzone onDrop={this.onDrop}>
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: 'dropzone' })}>
                                  <input {...getInputProps()} />
                                  SELECCIONE ARCHIVO .JRXML
                                </div>
                              )}
                            </Dropzone>
                          </a>
                        </Col>
                        <Col style={{ marginTop: "10px" }}>
                          <p className="form-date">
                            {" "}
                            <strong>
                              {this.state.archivoXls
                                ? '* ' + this.state.archivoXls.name
                                : " "
                              }
                            </strong>
                          </p>
                        </Col>

                      </Row>

                    </Form.Group>
                  }
                  <Form.Group className="form-group md-input" style={{ marginBottom: '35px ' }}>
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      required
                      name="metaFormato"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.metaFormato}
                      isInvalid={!!this.state.errors.formatoMetadato} />

                    <Form.Label className="md-label"> Formato de nombre c/ metadato </Form.Label>

                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.formatoMetadato}</small>
                    </Form.Control.Feedback>
                  </Form.Group>


                  <Form.Group className="form-group md-input" style={{ marginTop: "35px" }}>
                    <Form.Control
                      as="select"
                      className="form-control md-select"
                      name="origen"
                      onChange={this.handleInput}
                      value={this.state.origen}
                      isInvalid={!!this.state.errors.origen}>

                      <option key="" value="" selected></option>

                      {this.state.origenLote.map((elemento) => (
                        <option key={elemento.codigo} value={elemento.id}>
                          {elemento.nombre}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Label className="md-label"> Origen del lote </Form.Label>

                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.origen}</small>
                    </Form.Control.Feedback>

                  </Form.Group>


                  {this.state.carpetaPorDefectoActive &&
                    <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                      <Form.Control
                        className="form-control md-form-control"
                        type="text"
                        name="carpetaPorDefecto"
                        placeholder=" "
                        onChange={this.handleInput}
                        value={this.state.carpetaPorDefecto}
                        isInvalid={!!this.state.errors.carpetaPorDefecto} />
                      <Form.Label class="md-label"> Carpeta por defecto </Form.Label>

                      <Form.Control.Feedback type="invalid">
                        <small>{this.state.errors.carpetaPorDefecto}</small>
                      </Form.Control.Feedback>
                    </Form.Group>
                  }

                </Col>
                <Col xs={12} md={6}>
                  <p className="form-date">
                    {" "}
                    <strong>Opciones de firma</strong>
                  </p>
                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <Form.Control
                      as="select"
                      className="form-control md-select"
                      name="isFirmaEmpresa"
                      onChange={this.handleInput} value={this.state.isFirmaEmpresa} required>
                      <option key={true} value={true}>Si</option>
                      <option key={true} value={false}>No</option>
                    </Form.Control>
                    <Form.Label class="md-label"> Firma empresa </Form.Label>

                    <Form.Control.Feedback type="invalid">
                      <small>Debe seleccionar una opcion</small>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <Form.Control
                      as="select"
                      className="form-control md-select"
                      name="acciones"
                      onChange={this.handleInput} value={this.state.acciones}
                      isInvalid={!!this.state.errors.acciones}>

                      <option key="" value="" selected></option>

                      {this.state.accionesEmpleado.map((elemento) => (
                        <option key={elemento.codigo} value={elemento.id}>
                          {elemento.nombre}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Label class="md-label"> Acciones colaborador </Form.Label>

                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.acciones}</small>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group md-input">
                    <Form.Check
                      type="checkbox"
                      label="Permitir comentario en firma"
                      name="employeeComment"
                      checked={this.state.employeeComment}
                      onChange={this.handleChecked} />
                  </Form.Group>

                  <Form.Group className="form-group md-input" style={{ marginBottom: "35px" }}>
                    <Form.Control
                      as="select"
                      className="form-control md-select"
                      name="tipoEnvio"
                      onChange={this.handleInput}
                      value={this.state.tipoEnvio}
                      isInvalid={!!this.state.errors.tipoEnvio}>

                      <option key="" value="" selected></option>
                      {this.state.tipoEnvioMtess.map((elemento) => (
                        <option key={elemento.key} value={elemento.value}>
                          {elemento.descripcion}
                        </option>
                      ))}

                    </Form.Control >
                    <Form.Label className="md-label"> Tipo envio MTESS </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.tipoEnvio}</small>
                    </Form.Control.Feedback>
                  </Form.Group>

                  {(this.botonAprienciaFirma() && aparienciaFirma) && (
                    <Form.Group className="form-group md-input">
                      <Button variant="outline-primary" onClick={this.handleDialog}>Apariencia de firma</Button>
                    </Form.Group>
                  )}

                  <p className="form-date">
                    {" "}
                    <strong>Recordatorio</strong>
                  </p>

                  <Form.Group className="form-group md-input">
                    <Form.Check
                      type="checkbox"
                      label="Activar recordatorio de firma " name="cronJobActive"
                      onChange={this.handleChecked}
                      checked={this.state.cronJobActive} />
                  </Form.Group>

                  {this.state.cronJobActive &&
                    <div>
                      <Row>
                        <Form.Group className="form-group md-input">
                          <Form.Control
                            className="form-control md-form-control"
                            type="number"
                            min={0}
                            name="remindDaysAfterPublish"
                            placeholder=" "
                            onChange={this.handleInput}
                            value={this.state.remindDaysAfterPublish} />
                          <Form.Label class="md-label"> Días a partir de publicación </Form.Label>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3" style={{ marginTop: "20px" }}>

                        <Form.Group as={Col} className="form-group md-input">
                          <Form.Control
                            className="form-control md-form-control"
                            type="number"
                            min={0}
                            name="remindFrecuencyDays"
                            placeholder=" "
                            onChange={this.handleInput}
                            value={this.state.remindFrecuencyDays} />
                          <Form.Label class="md-label"> Dias </Form.Label>
                        </Form.Group>

                        <Form.Group as={Col} className="form-group md-input">
                          <Form.Control
                            className="form-control md-form-control"
                            type="number"
                            min={0}
                            name="remindFrecuencyHours"
                            placeholder=" "
                            onChange={this.handleInput}
                            value={this.state.remindFrecuencyHours} />
                          <Form.Label className="md-label"> Horas </Form.Label>
                        </Form.Group>

                        <Form.Group as={Col} className="form-group md-input">
                          <Form.Control
                            className="form-control md-form-control"
                            type="number"
                            placeholder=" "
                            min={0}
                            name="remindFrecuencyMinutes"
                            onChange={this.handleInput}
                            value={this.state.remindFrecuencyMinutes} />
                          <Form.Label className="md-label"> Minutos </Form.Label>
                        </Form.Group>
                      </Row>

                    </div>
                  }
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.handleClose("", "")} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              Guardar
            </Button>
          </Modal.Footer>
        </Form >
        <PantallaCarga open={this.state.openPantallaCarga} />
      </Container >
    );
  }
}



export default NuevoTipoDocumento;

import React from 'react';
import { connect } from 'react-redux';
import { editarEmpresa, guardarEmpresa, cargarImagen } from '../../components/empresas/EmpresaService'
import { getAuthorizationHeaderUpload, getAuthorizationHeader, URL as URLService } from '../../constans/constants';
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap"; 
import { logout } from '../login/SesionService';
import { validateForm } from "../../constans/validationUtils";

class Empresa extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      direccion: "",
      ruc: "",
      errors:{
        nombre: null,
        direccion: null,
        ruc: null,
      },
      estado: true,
      mostrarMensaje: false,
      mensaje: "",
      logo: null,
      urlLogo: '',
      openPantallaCarga: false
    }
  }

  reglasValidacionCampos = {
    nombre: [
      { required: true, message: 'Campo obligatorio' },
    ],
    direccion: [
      { required: true, message: 'Campo obligatorio' },
    ],
    ruc: [
      { required: true, message: 'Campo obligatorio' },
      { customValidator: (value, formData) => /^\d+-\d{1}$/.test(value), message: "Debe ingresar un RUC valido" }
    ]
  }

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensaje: false,
    });
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleUploadLogo = (event) => {
    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        250, 200,
        "PNG",
        0, 0,
        (uri) => {
          this.setState({
            urlLogo: URL.createObjectURL(uri),
            logo: uri
          })
        },
        "file",
      )

    } catch (error) {
      this.setState({
        mensaje: "Hubo un problema al cargar el nuevo logo",
        mostrarMensaje: true
      });
    }
  }

  handleEmpresaSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(this.state, this.reglasValidacionCampos);
    if (isValid) {
      this.setState({ openPantallaCarga: true, validated: true });
      if (this.props.type === "edit") {
        const json = {
          nombre: this.state.nombre,
          direccion: this.state.direccion,
          ruc: this.state.ruc,
          id: this.state.id,
          estado: this.state.estado
        };
        this.props.editarEmpresa(json).then((response) => {
          if (response.error === true) {
            try {
              const errores = JSON.parse(response.mensaje)
              this.setState(prevState => ({
                errors: {
                  ...prevState.errors,
                  nombre: errores.nombre,
                  direccion: errores.direccion,
                  ruc: errores.ruc
                },
                openPantallaCarga: false
              }))
            } catch (e) {
              this.setState({
                mostrarMensajeFlash: true,
                mensaje: response.mensaje,
                severity: "danger",
                openPantallaCarga: false
              })
            }
          } else {
            if (this.state.logo != null) {
              var id = response.datos.id
              var formData = new FormData()
              formData.append("file", this.state.logo)
              axios.post(URLService + "empresa/subirImagen/" + id, formData, getAuthorizationHeaderUpload())
            }
            this.props.handleClose(false, response.mensaje);
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
      } else if (this.props.type === "add") {
        const json = {
          nombre: this.state.nombre,
          direccion: this.state.direccion,
          ruc: this.state.ruc,
          estado: this.state.estado
        };
        this.props.guardarEmpresa(json).then((response) => {
          if (response.error === true) {
            try {
              const errores = JSON.parse(response.mensaje)
              this.setState(prevState => ({
                errors: {
                  ...prevState.errors,
                  nombre: errores.nombre,
                  direccion: errores.direccion,
                  ruc: errores.ruc
                },
                openPantallaCarga: false
              }))
            } catch (e) {
              this.setState({
                mostrarMensajeFlash: true,
                mensaje: response.mensaje,
                severity: "danger",
                openPantallaCarga: false
              })
            }
          } else {
            if (this.state.logo != null) {
              var id = response.datos.id
              var formData = new FormData()
              formData.append("file", this.state.logo)
              axios.post(URLService + "empresa/subirImagen/" + id, formData, getAuthorizationHeaderUpload())
            }
            this.props.handleClose(false, response.mensaje);
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
      }
    } else {
      this.setState({errors: errors});
    }

  }

  componentDidMount() {
    this.setState({
      nombre: this.props.empresa.nombre,
      direccion: this.props.empresa.direccion,
      ruc: this.props.empresa.ruc,
      id: this.props.empresa.id,
      estado: this.props.empresa.activo
    })
    if (this.props.type === "edit") {
      axios.get(URLService + "empresa/cargarImagen/" + this.props.empresa.id, getAuthorizationHeader())
        .then(res => {
          if (res.data.error !== true) {
            this.setState({
              urlLogo: "data:image/png;base64," + res.data
            })
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        })
    }
  }

  handleInput = (e) => {
    var name = e.target.name
    var val = e.target.value
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: null
      },
      [name]: val,
    }))
  }

  handleClose = () => {
    this.props.handleClose(false);
  }

  render() {
    return (
      <Container>
        <Form noValidate onSubmit={this.handleEmpresaSubmit}>
          <Modal.Header>
            <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col xs={12} md={6}>
                  <p className="form-date">
                    {" "}
                    <strong>Datos de la empresa</strong>
                  </p>
                  <Form.Group className="form-group md-input" style={{ marginBottom: '35px' }}>
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="nombre"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.nombre}
                      isInvalid={!!this.state.errors.nombre} 
                    />
                    <Form.Label class="md-label"> Nombre empresa </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.nombre}</small>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group md-input" style={{ marginBottom: '35px' }}>
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="direccion"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.direccion} 
                      isInvalid={!!this.state.errors.direccion}
                      />
                    <Form.Label class="md-label"> Direccion fisica </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.direccion}</small>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group md-input" style={{ marginBottom: '35px' }}>
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="ruc"
                      required
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.ruc} 
                      isInvalid={!!this.state.errors.ruc}  
                    />
                    <Form.Label class="md-label"> Nro. de RUC </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.ruc}</small>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group md-input" style={{ marginBottom: '35px' }}>
                    <Form.Check
                      type="switch"
                      id="estadoEmpresa"
                      checked={this.state.estado}
                      onChange={this.handleChange}
                      name="estado"
                      label="Activo"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="form-group md-input">
                    <Form.Label>
                      <p className="form-date">
                        <strong>Agregar un logo</strong>
                      </p>
                    </Form.Label>
                    <Form.Control type="file" onChange={this.handleUploadLogo} accept="images/*" />
                  </Form.Group>
                  <img
                    id="logoEmpresaImage"
                    src={this.state.urlLogo}
                    alt=""
                    accept="image/png, image/jpeg"
                    style={{ marginTop: '5px' }}
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
      </Container>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  guardarEmpresa: value => dispatch(guardarEmpresa(value)),
  editarEmpresa: value => dispatch(editarEmpresa(value)),
  cargarImagen: value => dispatch(cargarImagen(value)),
});

export default connect(null, mapDispatchToProps)(Empresa);
import React from "react";
import { Redirect, Link } from "react-router-dom"
import Cookies from 'universal-cookie';
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getRecibos, publicarLote, aceptarRRHHLote, aceptarRLLote, enviarLoteMtess, getEnviarNotificacion } from "./RecibosService";
import "../../styles/Empresa.css";
import Pin from "../recibos/Pin"
import { verificarRol, firmaRRHH, firmaRL, URL, gestorEmpresa } from '../../constans/constants'
import { Modal, ModalBody, Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import SeleccionPca from "../pca/SeleccionPca";
import LoginPsa from "../pca/LoginPsa";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { obtenerLote } from "../lotes/LoteService"
import arrowBack from '../../styles/icons/arrow_back.svg'
import pdfIcon from '../../styles/icons/pdf.svg'
import ExclamacionIcon from '../../styles/icons/exclamation-circle.svg'
import xCircle from '../../styles/icons/x-circle.svg';
import checkCircle from '../../styles/icons/check-circle.svg'

class Recibos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Ingrese Pin de Firma",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedRecibo: {
        recibo: "",
        fechaCarga: "",
        empleado: "",
        estado: "",
        estadoMTESS: false
      },
      idLote: "",
      fechaImportacionLote: "",
      descripcionLote: "",
      formatoDocumentoLote: "",
      cantidadDocumentoLote: "",
      firmaLote: null,
      numeroLote: "",
      tipoFirma: "",
      redirect: false,

      openMsgVentana: false,
      openSpinner: false,
      msgVentana: "",
      error: false,


      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      dialogPinOpen:  localStorage.getItem("tokenIscert") != null ? true : false,
      dialogAceptLote: false,
      open: false,

      openPantallaCarga: false,
      msjPantallaCarga: null,

      dialogEnvioNotificacion: false,

      dialogEnvioMtess: false,
      mostrarMensajeFlash: false,
      mensajeFlash: "",
      severityFlash: "",
      openSeleccionPca: false,
      openLoginPSA: false
    };
    localStorage.removeItem("redirectURLFirma")
    let pantallaAnterior = null;
    const datosPsa = null;
    const psaActivos = null;
    this.tableRef = React.createRef();
    this.setPantallaAnterior();
  }
 
  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  componentDidMount() {
    obtenerLote(this.props.match.params.idLote).then((res) => {
      this.setState({
        idLote: res.datos.loteRecibos.id,
        fechaImportacionLote: res.datos.loteRecibos.fechaCarga,
        descripcionLote: res.datos.loteRecibos.descripcion,
        formatoDocumentoLote: res.datos.loteRecibos.tipoDocumento.formato.extension,
        cantidadDocumentoLote: res.datos.loteRecibos.recibidos,
        numeroLote: res.datos.loteRecibos.numero,
        firmaLote: res.datos.loteRecibos.tipoDocumento.firmaEmpresa
      })

      this.psaActivos = res.datos.psaActivos
/*
      if (res.datos.loteRecibos.tipoDocumento.firmaEmpleado.codigo == "03" || res.datos.loteRecibos.tipoDocumento.firmaEmpleado.codigo == "04") {
        this.setState({ firmaLote: false })
      } else {
        this.setState({ firmaLote: true })
      }
*/
    })
  }
  handleDialog = (value) => {
    this.setState({
      dialogIsOpen: value,
    });
  };

  setPantallaAnterior = () => {
      localStorage.setItem("urlAnterior", window.location.href);
      this.pantallaAnterior = (localStorage.getItem("urlRaiz")).substring(localStorage.getItem("urlRaiz").indexOf("#"));
  }

  handleClosePsa = () =>{
    this.setState({openSeleccionPca: false})
  }

/*
  handleSeleccionPca = (value, data) => {
    this.datosPsa = data;
    this.setState({
      openSeleccionPca: value,
      openLoginPSA: data != null ? true : false
    });
  }
*/

  handleDialogEnvioMtess = (value) => {
    this.setState({
      dialogEnvioMtess: value
    })
  }

  handleDialogAceptLote = (value) => {
    this.setState({
      dialogAceptLote: value
    })
  }

  handleMsgVentana = (value) => {
    this.setState({ openMsgVentana: value, redirect: true })
  }

  handleLoginPSA = (value, data) => {
    if (data != null) {
      if (data.error) {
        this.setState({
          openLoginPSA: false,
          openMsgVentana: true,
          error: true,
          msgVentana: data.mensaje,
        })
      } else {
        localStorage.setItem("tokenIscert", data.datos.access_token)
        localStorage.setItem("codigoClienteFirma", this.datosPsa.id)
        localStorage.setItem("proveedorFirma", this.datosPsa.codigoCliente)
        this.setState({
          openLoginPSA: false,
          openMsgVentana: true,
          error: false,
          msgVentana: "Autenticado con exito",
        })
      }
    } else {
      this.setState({
        openLoginPSA: value
      })
    }
  }


  handleDialogPin = (open, data, firma) => {
    if (localStorage.getItem("tokenIscert") != null) {
      this.setState({ dialogPinOpen: open })
      if (data != null) {
        this.setState({
          msgVentana: data.mensaje,
          openMsgVentana: true,
          error: data.error,
          tipoFirma: firma,
          redirect: false
        })

      }
      localStorage.removeItem("tokenIscert")
      localStorage.removeItem("codigoClienteFirma")
      localStorage.removeItem("proveedorFirma")
    } else {
      this.setState({ openSeleccionPca: true })
    }

  }

  handleClose = (value) => {
    this.setState({
      open: value
    });
  };

  enviarNotificacion = () => {
    this.setState({ openPantallaCarga: true, msjPantallaCarga: "Enviando notificacion", dialogEnvioNotificacion: false });
    var idLote = this.state.idLote
    getEnviarNotificacion(idLote).then((res) => {
      this.setState({ openPantallaCarga: false });
      this.setState({
        msgVentana: res.mensaje, openMsgVentana: true, error: res.error
      })
    })
  }

  descargarLote = () => {
    window.location.href = URL + "lote/descargar/" + this.state.idLote;
  }

  enviarLoteMtess = () => {
    const cookies = new Cookies();
    var idLote = this.state.idLote
    var username = cookies.get("username")
    enviarLoteMtess(idLote, username).then((res) => {
      this.setState({ msgVentana: res.mensaje, openMsgVentana: true })
      this.handleDialogEnvioMtess(false)
    })

  }

  visualizarBotones = () => {
    if (this.props.match.params.estado == "nuevo" && verificarRol(this.props.roles, firmaRRHH)) {
      return (this.state.firmaLote ? <a className="btn btn-primary" style={{ backgroundColor: "#00DAF7" }} onClick={this.handleDialogPin}>
        Firmar
      </a> : <a className="btn btn-primary" style={{ backgroundColor: "#00DAF7" }} onClick={this.handleDialogAceptLote}>
        Aceptar Lote
      </a>
      )

    }
    if (this.props.match.params.estado == "firmado" && verificarRol(this.props.roles, firmaRL)) {
      return (this.state.firmaLote ? <a className="btn btn-primary" style={{ backgroundColor: "#00DAF7" }} onClick={this.handleDialogPin}>
        Firmar
      </a> : <a className="btn btn-primary" style={{ backgroundColor: "#00DAF7" }}  onClick={this.handleDialogAceptLote}>
        Aceptar Lote
      </a>
      )
    }
    if (this.props.match.params.estado == "publicar") {
      return (<Button variant="info" onClick={this.handleClose}>
        Publicar lote
      </Button>
      )
    }
    if (this.props.match.params.estado == "publicado" && verificarRol(this.props.roles, gestorEmpresa)) {
      return (<Button onClick={() => this.setState({ dialogEnvioNotificacion: true })} variant="info" color="primary" type="Button">
        Enviar recordatorio
      </Button>
      )
    }
    if (this.props.match.params.estado == "cerrado") {
      return (<Button variant="info" onClick={() => this.handleDialogEnvioMtess(true)}>
        Enviar a MTESS
      </Button>
      )
    }
  }

  publicarLote = () => {
    this.setState({ openPantallaCarga: true, open: false })
    publicarLote(this.state.idLote).then((response) => {
      this.setState({
        msgVentana: response.mensaje,
        openMsgVentana: true,
        isPublicado: true
      })
    })
  }

  aceptarRRHH = () => {
    this.setState({ openPantallaCarga: true })
    aceptarRRHHLote(this.state.idLote).then((response) => {
      this.setState({
        dialogAceptLote: false,
        msgVentana: response.mensaje,
        openMsgVentana: true,
        tipoFirma: "RRHH",
        openPantallaCarga: false
      })
    })
  }

  aceptarRL = () => {
    this.setState({ openPantallaCarga: true })
    aceptarRLLote(this.state.idLote).then((response) => {
      this.setState({
        dialogAceptLote: false,
        msgVentana: response.mensaje,
        openMsgVentana: true,
        tipoFirma: "RL",
        openPantallaCarga: false
      })
    })
  }

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  validarMtess = () => {
    if (this.props.match.params.estado == "cerrado")
      return true;
    else
      return false

  }


  render() {
    if (this.state.tipoFirma == 'RRHH' && this.state.redirect) {
      return (<Redirect to={"/lote/nuevos"} />)
    } else if (this.state.tipoFirma == 'RL' && this.state.redirect) {
      return (<Redirect to={"/lote/firmados"} />)
    } else if (this.state.isPublicado && this.state.redirect) {
      return (<Redirect to={"/lote/publicar"} />)
    } else {
      return (
        <div>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <div className="dashboardContent">
            <div className="container-fluid">

              <PantallaCarga open={this.state.openPantallaCarga} mensaje={this.state.msjPantallaCarga} />
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.dialogEnvioMtess}
                backdrop="static"
                keyboard="false"
              >
                <Container>
                  <Modal.Body>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                      <img src={ExclamacionIcon} style={{margin: '40px'}}></img>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                        <p><strong>¿Está seguro que desea enviar este lote a MTESS?</strong></p>
                      </Col>
                    </Row>
                    <Row style={{ background: "#EDF2F9", padding: "5px" }}>
                      <Col>
                        <p><strong>Lote: </strong>{this.state.idLote} | {this.state.fechaImportacionLote}</p>
                        <p><strong>Descripcion:</strong> {this.state.descripcionLote}</p>

                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => this.handleDialogEnvioMtess(false)} variant="ligth">Cancelar</Button>
                    <Button onClick={this.enviarLoteMtess} variant="primary" autoFocus>
                      Enviar
                    </Button>
                  </Modal.Footer>
                </Container>
              </Modal>
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.dialogAceptLote}
                backdrop="static"
                keyboard="false"
              >
                <Container>
                  <Modal.Body>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                      <img src={ExclamacionIcon} style={{margin: '40px'}}></img>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                        <p><strong>¿Está seguro que desea aceptar el Lote?</strong></p>
                      </Col>
                    </Row>
                    <Row style={{ background: "#EDF2F9", padding: "5px" }}>
                      <Col>
                        <p><strong>Lote: </strong>{this.state.idLote} | {this.state.fechaImportacionLote}</p>
                        <p><strong>Descripcion:</strong> {this.state.descripcionLote}</p>

                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => this.handleDialogAceptLote(false)} variant="ligth">Cancelar</Button>
                    <Button onClick={this.props.match.params.estado == "firmado" ? this.aceptarRL : this.aceptarRRHH} variant="primary" autoFocus>
                      Aceptar
                    </Button>
                  </Modal.Footer>
                </Container>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.dialogEnvioNotificacion}
                backdrop="static"
                keyboard="false"
              >
                <Modal.Body>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                    <img src={ExclamacionIcon} style={{margin: '40px'}}></img>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <p>Se enviará una notificacion por correo electronico al colaborador asignado a este lote. ¿Esta seguro?</p>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.setState({ dialogEnvioNotificacion: false })} variant="ligth">Cancelar</Button>
                  <Button onClick={() => this.enviarNotificacion()} variant="primary" autoFocus>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.openMsgVentana}
                backdrop="static"
                keyboard="false"
              >
                <Container>
                  <Modal.Body>
                    {
                      <div>
                        <Row className="justify-content-center">
                          <Col className="text-center text-md-right">
                            {this.state.error ?
                              <img src={xCircle} style={{margin: '40px'}}></img>
                              :
                              <img src={checkCircle} style={{margin: '40px'}}></img>
                            }
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col className="text-center text-md-right">
                            <p><strong>{this.state.msgVentana}</strong></p>
                          </Col>
                        </Row>
                      </div>
                    }
                    <Modal.Footer>
                      <Button variant="primary" onClick={() => this.handleMsgVentana(false)}>
                        Aceptar
                      </Button>
                    </Modal.Footer>
                  </Modal.Body>
                </Container>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.open}
                hide={this.state.handleClose}
                backdrop="static"
                keyboard="false"
              >
                <Container>
                  <Modal.Body>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                      <img src={ExclamacionIcon} style={{margin: '40px'}}></img>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                        <p><strong>¿Estas Seguro que desea publicar el lote?</strong></p>
                      </Col>
                    </Row>
                    <Row style={{ background: "#EDF2F9", padding: "5px" }}>
                      <Col>
                        <p><strong>Lote: </strong>{this.state.numeroLote} | {this.state.fechaImportacionLote}</p>
                        <p><strong>Descripcion:</strong> {this.state.descripcionLote}</p>

                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => this.handleClose(false)} variant="ligth">Cancelar</Button>
                    <Button onClick={this.publicarLote} variant="primary" autoFocus>
                      Publicar lote
                    </Button>
                  </Modal.Footer>
                </Container>
              </Modal>

              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.dialogPinOpen}
                onHide={this.handleDialogPin}
                backdrop="static"
                keyboard="false"
              >
                <Pin
                  estado={this.props.match.params.estado}
                  //idLote={this.state.idLote}
                  numeroLote={this.state.numeroLote}
                  idLote={this.props.match.params.idLote}
                  formatoDocumento={this.state.formatoDocumentoLote}
                  fechaImportacionLote={this.state.fechaImportacionLote}
                  descripcionLote={this.state.descripcionLote}
                  cantidadDocumentoLote={this.state.cantidadDocumentoLote}
                  type={this.state.dialogType}
                  submitText={this.state.submitText}
                  disabledInput={this.state.disabledInput}
                  handleClose={this.handleDialogPin}
                  dialogTitle={this.state.tipoFirma == 'RRHH' ? 'Firma RRHH' : 'Firma representante legal'}
                />

              </Modal>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.openSeleccionPca}
                onHide={this.handleClosePsa}
                backdrop="static"
                keyboard="false"
              >
                <SeleccionPca
                  handleClose={this.handleClosePsa}
                  url={window.location.href}
                  listaPsa={this.psaActivos}
                />
              </Modal>

              <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.openLoginPSA}
                onHide={() => this.handleLoginPSA(false, null)}
                backdrop="static"
                keyboard="false"
              >
                <LoginPsa
                  handleClose={this.handleLoginPSA}
                  data={this.datosPsa}
                />

              </Modal>

              <div className="sectionHeader" style={{ paddingBottom: '20px' }}>
                <div className="actionTitle" >
                <a type='button' style={{ paddingTop: '7px' }}>
                    <img src={arrowBack} onClick={() => window.location.href = this.pantallaAnterior}/>
                </a>
                  <h1 className="pageTitle"> Lote de Recibos #{this.state.numeroLote}</h1>
                </div>
                <div className="actions">
                  {this.state.firmaLote != null && this.visualizarBotones()}
                  <Button variant="primary" onClick={this.descargarLote}> Descargar Lote </Button>
                </div>
              </div>
              <div className="contentPage card" style={{ padding: '0px' }}>
                <MaterialTable
                  title="Recibos"
                  style={{ borderRadius: ".5rem" }}
                  tableRef={this.tableRef}
                  columns={[
                    { title: "Recibo", field: "recibo" },
                    { title: "Fecha de Carga", field: "fechaCarga" },
                    { title: "Colaborador", field: "empleado" },
                    { title: "Estado", field: "estado" },
                    { title: "Estado MTESS", field: "estadoMTESS", type: "boolean", hidden: !this.validarMtess() },
                    { title: "Mensaje de MTESS", field: "mensajeMTESS", hidden: !this.validarMtess() }
                  ]}
                  data={(query) =>

                    new Promise((resolve, reject) => {
                      this.props.getRecibos(query.page, query.pageSize, query.search, this.props.match.params.idLote).then(() => {
                        resolve({
                          data: this.props.recibosData.recibos,
                          page: this.props.recibosData.page,
                          totalCount: this.props.recibosData.totalCount,
                        });
                      });
                    })
                  }
                  actions={[
                    rowData => ({
                      icon: () =>
                        <Link to={{
                          pathname: '/recibo/' + rowData.recibo + '/lote/' + rowData.idLote + '/ver',
                          /*
                          state: {
                            idRecibo: rowData.recibo, idLote: rowData.idLote,
                            redirect: this.props.location.pathname,
                            estado: this.props.match.params.estado
                          }
                          */
                        }}>
                          <img src={pdfIcon} />
                        </Link>,
                      tooltip: 'Ver',
                      onClick: (rowData) => {
                      }
                    })
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    sorting: false,
                    paginationType: "stepped",
                    search: true,
                    showTextRowsSelected: false,
                    showTitle: false,
                    toolbar: true,
                    detailPanelColumnAlignment: "right",
                    headerStyle: {
                      background: "#edf2f9",
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "No hay resultados que mostrar",
                    },
                    header: {
                      actions: "Acciones",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      nextTooltip: "Página Siguiente",
                      previousTooltip: "Página Anterior",
                      firstTooltip: "Primera Página",
                      lastTooltip: "Última Página",
                      labelRowsSelect: "Filas",
                    },
                    toolbar: {
                      searchPlaceholder: "Buscar",
                      searchTooltip: "Buscar",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

  }
}

const mapDispatchToProps = (dispatch) => ({
  getRecibos: (page, size, search, idLote) => dispatch(getRecibos(page, size, search, idLote)),
});

const mapStateToProps = (state) => ({
  recibosData: state.recibosData,
  roles: state.rolesData,
  firmaRRHH: state.firmaRRHH,
  firmaRL: state.firmaRL
});

export default connect(mapStateToProps, mapDispatchToProps)(Recibos);

import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getGrupos, eliminarGrupo } from "../usuario/UsuarioService";
import "../../styles/Empresa.css";
import DocumentDialogContent from "./GrupoUsuario";
import GrupoUsuarioDatos from "./GrupoUsuarioDatos";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import {Modal, Button,Col,Row,Container } from "react-bootstrap";
import  Navbar  from '../home/Navbar';
import ExclamacionIcon from '../../styles/icons/exclamation-circle.svg'
import delete_forever from '../../styles/icons/delete.svg';
import edit from '../../styles/icons/edit.svg';
import visibility from '../../styles/icons/visibility.svg';


class GruposUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Registrar nueva Empresa",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedGruposUsuarios: {
        id: "",
        descripcion: "",
        activo: false,
        miembros: {}
      },
      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      mensajeFlash: "",
      mostrarMensajeFlash: false
    };
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  handleDialog = (value, mensaje = "") => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
      if (mensaje != "") {
        this.setState({
          mostrarMensajeFlash: true,
          mensajeFlash: mensaje
        });
      }
    }
    this.setState({ dialogIsOpen: value });
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  handleDialogVerDatos = (value) => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
    }
    this.setState({
      dialogIsOpenVerDatos: value,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDisableGrupo=()=>{
    this.handleClose();
    this.props.eliminarGrupo(this.state.selectedGruposUsuarios.id).then((response) => {
      {
        if (response.error) {
          this.setState({
            mensajeFlash: response.mensaje,
            mensajeFlashSeverity: "danger"
          });
        } else {
          this.tableRef.current.onQueryChange();
        }
        this.setState({
          mensajeFlash: response.mensaje,
          mostrarMensajeFlash: true
        });
      }
    })
  }

  handleNewGrupo = () => {
    this.setState(
      {
        dialogTitle: "Registrar nuevo Grupo",
        selectedGruposUsuarios: {
            id: "",
            descripcion: "",
            activo: false
        },
        dialogType: "add",
        submitText: "Guardar",
        disabledInput: false,
      },
      () => {
        this.handleDialog(true);
      }
    );
  };

  handleVerdatos = () => {
    this.setState(
      {
        dialogTitle: "Detalles Empresa",
        selectedGruposUsuarios: {
            id: "",
            descripcion: "",
            activo: false
        },
        dialogType: "add",
        submitText: "Guardar",
        disabledInput: false,
      },
      () => {
        this.handleDialogVerDatos(true);
      }
    );
  };

  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
          <MensajeFlash 
            mensaje={this.state.mensajeFlash} 
            severity={"success"} 
            open={this.state.mostrarMensajeFlash}
            handleClose={this.handleCloseMensajeFlash}/>
            <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.dialogIsOpen}
              onHide={this.handleDialog}
              backdrop="static"
              keyboard="false"
            >
              <DocumentDialogContent
                grupo={this.state.selectedGruposUsuarios}
                handleClose={this.handleDialog}
                type={this.state.dialogType}
                submitText={this.state.submitText}
                disabledInput={this.state.disabledInput}
                dialogTitle={this.state.dialogTitle}
              />
            </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.dialogIsOpenVerDatos}
          onHide={this.handleDialogVerDatos}
          backdrop="static"
          keyboard="false">
            <GrupoUsuarioDatos
              grupo={this.state.selectedGruposUsuarios}
              handleClose={this.handleDialogVerDatos}
              type={this.state.dialogType}
              submitText={this.state.submitText}
              disabledInput={this.state.disabledInput}
              dialogTitle={this.state.dialogTitle}
            />
          </Modal>
          <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.open}
              onHide={this.handleClose}
              backdrop="static"
              keyboard="false"
            >
              <Container>
                <Modal.Body>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                    <img src={ExclamacionIcon} style={{margin: '40px'}}></img>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <p><strong>Estas Seguro que desea eliminar el grupo seleccionado?</strong></p>
                    </Col>
                  </Row>
                  <Row style={{ background: "#EDF2F9" }}>
                    <Col>
                      <p><strong>Descripción:</strong> {this.state.selectedGruposUsuarios.descripcion}</p>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.handleClose} variant="contained">Cancelar</Button>
                  <Button onClick={this.handleDisableGrupo} color="primary" autoFocus>
                    Aceptar
                  </Button>
                </Modal.Footer>
              </Container>
            </Modal>
            <div className="sectionHeader">
              <h1 className="pageTitle"> Grupo Usuarios </h1>
              <div className="actions">
                <Button
                  color="primary"
                  onClick={this.handleNewGrupo}
                >
                  Nuevo Grupo
                </Button>
              </div>
            </div>
            <Navbar
              menuActual={window.location.hash}
              seccionActual={'administracion'}
            />
            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                title="Grupo de Usuarios"
                tableRef={this.tableRef}
                columns={[
                  { title: "Descripcion", field: "descripcion" },
                  { title: "Activo", field: "activo", type: "boolean" },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    this.props.getGrupos(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.gruposData.grupos,
                        page: this.props.gruposData.page,
                        totalCount: this.props.gruposData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  {
                    icon: ()=> <img src={edit} alt='Editar'/>,
                    tooltip: 'Editar Grupo',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Editar Grupo",
                        selectedGruposUsuarios: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleDialog(true);
                      });
                    }
                  },
                  {
                    icon: () => <img src={visibility} alt="Ver" />,
                    tooltip: 'Ver',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Detalles Grupo",
                        selectedGruposUsuarios: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleDialogVerDatos(true);
                      });
                    }
                  },
                  {
                    icon: () => <img src={delete_forever} alt="Eliminar"/>,
                    tooltip: 'Eliminar',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Eliminar Aplicacion Externa",
                        selectedGruposUsuarios: rowData,
                        dialogType: "eliminar",
                        submitText: "Eliminar",
                        disabledInput: false
                      }, () => {
                        this.handleClickOpen();
                      });
                    }
                  }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold",
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGrupos: (page, size, search) => dispatch(getGrupos(page, size, search)),
  eliminarGrupo: (id) => dispatch(eliminarGrupo(id)),
});

const mapStateToProps = (state) => ({
  gruposData: state.gruposData,
});

export default connect(mapStateToProps, mapDispatchToProps)(GruposUsuarios);
import React from "react";
import axios from "axios";
import { URL, getAuthorizationHeader } from "../../constans/constants";
import { firmarRRHH, firmarRL } from './RecibosService';
import ProgressBarRecibo from "../mensajeFlash/ProgressBarRecibo";
import { getUsuarioLogueado, getEmpresa } from "../../components/login/SesionService";
import { Form, Modal, Container, Button } from "react-bootstrap";
import {logout} from '../login/SesionService'
import { createGostCanvasAndGetAppareance, loadSignature } from "../tiposDocumento/AparienciaServicios";

class Pin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idLote: "",
      keystore: 0,
      formatoFirmaEmpresa: null,
      pin: "",
      respuesta: "",
      severity: "success",
      mostrarPantallaCarga: false,
      redirect: "",
      signatureEmpresa: {},
      datosUsuarios: {},
      datosEmpresa: {},
      tipoFirma: "",
      openPantallaCarga: false,
      validated: false,
      recibosFirmados: {},
      cantidadRecibos: 0,
      cantidadFirmadas: 0,
      respuestaRecibosFirmados: {},
      isLoaded: false
    };
  }

  handleClickCancelar = () =>{
    this.props.handleClose(false, null, this.state.tipoFirma);
    return;
  }

  handleClose = (data) => {
    if(data.error){
      this.props.handleClose(false, data, this.state.tipoFirma);
       return;
     }
     var cantidadRecibosFirmados = data.datos.filter(item => item.firmado === true).length;
     if(cantidadRecibosFirmados == this.state.recibosFirmados.length){
       this.props.handleClose(false, data, this.state.tipoFirma);
     }
    this.actualizarDatosRecibosFirmados(data.datos)
     /*  this.props.handleClose(false, data, this.state.tipoFirma);  */
  };

  actualizarDatosRecibosFirmados = (data) =>{
    var cantidadRecibosFirmados = data.filter(item => item.firmado === true).length;
    this.setState({cantidadRecibos:data.length,cantidadFirmadas:cantidadRecibosFirmados})
  }

  handleFirmasPDF = (value) => {
    this.setState({ tipoFirma: value })
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handlePinSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true })
    } else {
      this.setState({ openPantallaCarga: true });
      var responseError = {};
      if (this.props.formatoDocumento == "PDF") {
        var formatoEmpresa = await loadSignature(this.state.formatoFirmaEmpresa, this.state.datosUsuarios, this.state.datosEmpresa, true);
        var apariencia = null;
        try {
          for (const item of this.state.recibosFirmados) {
            var json = {
              idLote: this.props.idLote,
              idRecibo: item.idRecibo,
              keystore: this.state.keystore,
              appearance: apariencia,
              formatoFirmaEmpresa: this.state.formatoFirmaEmpresa,
              pin: this.state.pin
            };
        
            if (formatoEmpresa.appearance) {
              json = await createGostCanvasAndGetAppareance(formatoEmpresa, json, this.props.estado, this.handleClose, this.handleFirmasPDF);
              const response = await this.firmar(json);
              if (response.data.error) {
                responseError = response
                throw new Error(responseError);
              }
              this.handleClose(response.data);
            }
          }
      }catch(error){
        this.props.handleClose(false, responseError.data, this.state.tipoFirma);
      }
        /* this.setState({ openPantallaCarga: false }); */
      } else {
        try{
          for (const item of this.state.recibosFirmados){
            const json = {
              idLote: this.props.idLote,
              idRecibo: item.idRecibo,
              keystore: this.state.keystore,
              formatoFirmaEmpresa: this.state.formatoFirmaEmpresa,
              pin: this.state.pin
            };
            if (this.props.estado == 'nuevo') {
                const response = await firmarRRHH(json)
                if (response.data.error) {
                  responseError = response
                  throw new Error(responseError);
                }
                this.handleFirmasPDF('RRHH')
                this.handleClose(response.data);
            } else if (this.props.estado == 'firmado') {
              const response = await firmarRL(json);
              if (response.data.error) {
                responseError = response
                throw new Error(responseError);
              }
              this.handleFirmasPDF('RL');
              this.handleClose(response.data);
            }
          }
        }catch(error){
          this.props.handleClose(false, responseError.data, this.state.tipoFirma);
        }
      }
      }
  };

  firmar = async (json)=>{
    if(this.props.estado == 'nuevo'){
      const response = await firmarRRHH(json)
      this.handleFirmasPDF('RRHH')
      return response;
    }else if(this.props.estado == 'firmado'){
      firmarRL(json).then((response) => {
        this.handleFirmasPDF('RL')
        return response;
      });
    }
  }

  componentDidMount() {
    var token = {
      token:localStorage.getItem("tokenIscert"),
      psa:localStorage.getItem("codigoClienteFirma")}
    axios
      .post(URL + "payrolls/iscert/keystore",token, getAuthorizationHeader())
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ keystore: res.data.datos.keystores[0].id, isLoaded: true });
        } else {
          /*
          this.setState({
            severity: "danger",
            respuesta: res.data.mensaje
          });
          */
          this.handleClose(res.data);
          //this.handleClose(this.state.respuesta, this.state.severity);
        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      })
    axios
      .get(
        URL + "lote/getEmpresaSignFormat/" + this.props.idLote,
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ formatoFirmaEmpresa: res.data.datos });
        } else {

        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
    getUsuarioLogueado().then((response) => {
      this.setState({ datosUsuarios: response });
    });
    getEmpresa().then((response) => {
      this.setState({ datosEmpresa: response })
    });
    if(this.props.estado == 'nuevo'){
      axios
      .get(
        URL + "lote/recibosFirmados/RRHH/" + this.props.idLote,
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ recibosFirmados: res.data.datos });
        } else {

        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
    }
    else if (this.props.estado == 'firmado'){
      axios
      .get(
        URL + "lote/recibosFirmados/RL/" + this.props.idLote,
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ recibosFirmados: res.data.datos });
        } else {

        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
    }
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };
  render() {
    return (
      <Container>
        <ProgressBarRecibo cantidadProcesada={this.state.cantidadFirmadas} cantidadTotal={this.state.cantidadRecibos} open={this.state.openPantallaCarga} />
        <Form noValidate validated={this.state.validated} onSubmit={this.handlePinSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> Firma de documento </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="datosLotes">
              <p className="form-date">
                {" "}
                <strong>Confirmar datos:</strong>
              </p>
              <div className="lote-date"> Lote: {this.props.numeroLote} | fecha importacion: {this.props.fechaImportacionLote} </div>
              <div className="lote-row">
                <p className="lote-text"> <strong>Descripción: </strong> {this.props.descripcionLote} </p>
                <p clclassNameass="lote-text"> <strong>Cantidad: </strong> {this.props.cantidadDocumentoLote}. </p>
              </div>
            </div>
            <p className="form-date"> <strong> Ingresa el Pin de Firma para firmar el lote de Recibos seleccionado:</strong> </p>
            <Form.Group className="form-group md-input">
              <input
                className="form-control md-form-control"
                type="password"
                name="pin"
                onChange={this.handleInput}
                value={this.state.pin}
                required
              />
              <Form.Label class="md-label"> Pin de firma </Form.Label>
              <Form.Control.Feedback type="invalid">
                <small>Campo requerido</small>
              </Form.Control.Feedback>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClickCancelar} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit" disabled={!this.state.isLoaded}>
              Firmar
            </Button>
          </Modal.Footer>
        </Form>
      </Container>
    )
    //}
  }
}

export default Pin;

import React from 'react';
import { connect } from 'react-redux';
import { editarNumeroPatronal, guardarNumeroPatronal } from '../../components/numeroPatronal/NumeroPatronalService'
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { validateForm } from "../../constans/validationUtils";

class NumeroPatronal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      numeroPatronal: "",
      descripcion: "",
      errors:{
        numeroPatronal: null,
        descripcion: null
      },
      id: "",
      mostrarMensaje: false,
      mensaje: "",
      severity: "",
      openPantallaCarga: false,
    }
  }

  reglasValidacionCampos = {
    numeroPatronal: [
      { customValidator: (value, formData) => { return /^\d+$/.test(value) }, message: 'Ingrese un número válido' },
    ],
    descripcion: [
      { required: true, message: 'Ingrese una descripcion' },
    ]
  }

  handleClose = () => {
    this.props.handleClose(false, this.state.mensaje, this.state.severity);
  }

  handleNumeroPatronalSubmit = event => {
    event.preventDefault();
    const { isValid, errors } = validateForm(this.state, this.reglasValidacionCampos);
    if (isValid) {
      this.setState({ openPantallaCarga: true });
      if (this.props.type === "edit") {
        const json = {
          numeroPatronal: this.state.numeroPatronal,
          descripcion: this.state.descripcion,
          id: this.state.id
        };
        this.props.editarNumeroPatronal(json).then((response) => {
          if (!response.error) {
            this.setState({
              mensaje: response.mensaje,
              severity: "success"
            })
          } else {
            this.setState({
              mensaje: response.mensaje,
              severity: "danger"
            })
          }
          this.handleClose();
        });
      } else if (this.props.type === "add") {
        const json = {
          numeroPatronal: this.state.numeroPatronal,
          descripcion: this.state.descripcion,
          id: this.state.id
        };
        this.props.guardarNumeroPatronal(json).then((response) => {
          if (!response.error) {
            this.setState({
              mensaje: response.mensaje,
              severity: "success",
              openPantallaCarga: false
            })
            this.handleClose();
          } else {
            try {
              const errores = JSON.parse(response.mensaje)
              this.setState(prevState => ({
                errors: {
                  ...prevState.errors,
                  numeroPatronal: errores.numeroPatronal,
                  descripcion: errores.descripcion
                },
                openPantallaCarga: false
              }))
            } catch (e) {
              this.setState({
                mostrarMensajeFlash: true,
                mensaje: response.mensaje,
                severity: "danger",
                openPantallaCarga: false
              })
              this.handleClose(true)
            }
          }
        });
      }
    } else {
      this.setState({errors: errors});
    }

  }

  componentDidMount() {
    if (this.props.type === "edit") {
      this.setState({
        numeroPatronal: this.props.numeroPatronal.numero,
        descripcion: this.props.numeroPatronal.descripcion,
        id: this.props.numeroPatronal.id
      })
    }
  }

  handleInput = (e) => {
    var name = e.target.name
    var val = e.target.value
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: null
      },
      [name]: val,
    }))
  }
  render() {
    return (
      <Container>
        <Form noValidate onSubmit={this.handleNumeroPatronalSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}>
              {" "}
              {this.props.dialogTitle}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col style={{ padding: "10px"}}>
                <Form.Group className="form-group md-input" controlId="formnumeroPatronal">
                  <div className="form-group md-input">
                    <Form.Control
                      className="form-control md-form-control"
                      name="numeroPatronal"
                      id="numeroPatronal"
                      placeholder=" "
                      onChange={this.handleInput}
                      required
                      value={this.state.numeroPatronal}
                      isInvalid={!!this.state.errors.numeroPatronal}
                    />
                    <Form.Label class="md-label"> Número MTESS Patronal </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.numeroPatronal}</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "10px", marginTop: "3px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                  <Form.Control
                      className="form-control md-form-control"
                      as="textarea"
                      name="descripcion"
                      required
                      placeholder=" "
                      rows="2"
                      onChange={this.handleInput}
                      value={this.state.descripcion} 
                      isInvalid={!!this.state.errors.descripcion}
                    />

                    <Form.Label class="md-label"> Descripción </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.descripcion}</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
        <PantallaCarga open={this.state.openPantallaCarga} />
      </Container>
    )
  }

}

const mapDispatchToProps = dispatch => ({
  guardarNumeroPatronal: value => dispatch(guardarNumeroPatronal(value)),
  editarNumeroPatronal: value => dispatch(editarNumeroPatronal(value))
});

export default connect(null, mapDispatchToProps)(NumeroPatronal);
import React from "react";
import { connect } from "react-redux";
import {
  guardarAplicacionExterna,
  editarAplicacionExterna,
} from "../../components/aplicacionExterna/AplicacionExternaService";
import {
  URL,
  getAuthorizationHeader,
  idEmpresa,
  getAuthorizationHeaderUpload,
} from "../../constans/constants";
import { exportarDocumento } from "../lotes/LoteService";
import axios from "axios";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { logout } from "../login/SesionService"
import { Container, Row, Col, Modal, Form, Button, Table } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { getUsuario } from "../usuario/UsuarioService";
import { validateForm } from "../../constans/validationUtils";
import cloudUploadIcon from '../../styles/icons/cloud_upload.svg'

class ImportarDocumento extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
    this.state = {
      descripcion: "",
      numeroPatronal: [],
      gruposUsuarios: [],
      tipoDocumento: [],
      lote: [],
      id: "",
      date: date,
      file: null,
      uploadDisabled: false,
      numeroPatronalID: "",
      tipoDocumentoID: "",
      formatoTipoDocumentoSeleccionado: "",
      idGrupo: "",
      isCarpetaPorDefecto: false,
      carpetaPorDefecto: "",
      fileErrorMsg: "",
      errors: {
        descripcion: null,
        numeroPatronalID: null,
        tipoDocumentoID: null,
        idGrupo: null,
        carpetaPorDefecto: null,
        idColaborador: null,
      },
      path: "",
      mensaje: "",
      severity: "",
      mostrarMensajeFlash: false,
      submit: false,
      openPantallaCarga: false,
      requiredNroMtess: false,
      grupoCheckBox: false,
      idColaborador: "",
      usuarioData: {},
      hayDatosUsuario: false,
      mensajeConsultaUsuario: "",
      cedula: ""
    };
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  reglasValidacionCampos = {
    descripcion: [
      { required: true, message: 'Debe proveer una descripcion para el lote' },
    ],
    tipoDocumentoID: [
      {
        customValidator: (value, formData) => {
          return value > 0
        }, message: 'Debe seleccionar un tipo de documento'
      },
    ],
    numeroPatronalID: [
      {
        customValidator: (value, formData) => {
          if (!this.validarExtensionDocumento())
            return true
          else
            return value > 0
        }, message: 'Debe elegir una opcion'
      },
    ],
    carpetaPorDefecto: [
      {
        customValidator: (value, formData) => {
          if (!this.state.isCarpetaPorDefecto)
            return true
          else
            return /^(\/[^\/]+)+$/.test(value)
        }, message: 'Ingrese una direccion valida'
      },
    ],
    idColaborador: [
      { required: true, message: 'Ingrese un numero de cedula' },
      { customValidator: (value, formData) => /^\d+$/.test(value), message: 'Ingrese sólo números' },
    ]
  }

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleDialogImport = (value) => {
    this.setState({
      resultImportPopUp: value
    })
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({
      file: event.target.files[0],
      size: event.target.files[0].size,
      filename: event.target.files[0].name,
      fileErrorMsg: ""
    });
  }

  onDrop = (files) => {
    this.setState({
      file: files[0],
      filename: files[0].name,
      fileErrorMsg: ''
    });
  }
  agregarUsuario(usuariosData) {
    this.setState({ idColaborador: usuariosData.id })
  }
  handleChecked = (event) => {
    var name = event.target.name;
    var val = event.target.checked;
    this.setState({
      [name]: val,
      idGrupo: ""
    });
  }

  checkFileExtension = (tiposDocumentos, documentoSeleccionado) => {
    if (this.state.file == null)
      return false;
    var fileName = this.state.file.name
    var extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    var formatoTipoDocumento = tiposDocumentos.find((aux) => aux.id == documentoSeleccionado)
    if (extension.toLowerCase() === formatoTipoDocumento.formato.toLowerCase())
      return formatoTipoDocumento.formato.toLowerCase();
    return null;
  }

  obtenerUsuario = () => {
    if (this.state.cedula == "") {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          idColaborador: "Ingrese un numero de cedula"
        },
      }))
    } else {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          idColaborador: null
        },
        usuarioData: {},
        hayDatosUsuario: false
      }))
      axios
        .get(URL + "usuario/obtenerDatosColaborador/" + this.state.cedula, getAuthorizationHeader())
        .then((res) => {
          if (!res.data.error) {
            this.setState({ usuarioData: res.data.datos, hayDatosUsuario: true, idColaborador: res.data.datos.cedula})
          } else {
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                idColaborador: "Usuario no encontrado"
                //idColaborador:  res.data.mensaje
              },
            }))
          }
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
    }
  }

  validarFormulario = () => {
    var { isValid, errors } = validateForm(this.state, this.reglasValidacionCampos);
    var extensionArchivo = this.checkFileExtension(this.state.tipoDocumento, this.state.tipoDocumentoID);
    if (extensionArchivo != null)
      return { isValid, errors }
    else {
      this.setState({ fileErrorMsg: "*Debe subir un archivo en formato " + this.state.formatoTipoDocumentoSeleccionado })
      return { isValid, errors }
    }
  }

  subirArchivo = event => {
    event.preventDefault();
    const { isValid, errors } = this.validarFormulario();
    /*
    const { isValid, errors } = validateForm(this.state, this.reglasValidacionCampos);
    const extensionArchivo = this.checkFileExtension(this.state.tipoDocumento, this.state.tipoDocumentoID);
    */
    if (isValid) {
      const data = {
        description: this.state.descripcion,
        file: null,
        filename: this.state.filename,
        empresaId: idEmpresa(),
        idGrupo: this.state.idGrupo,
        numeroPatronalId: this.state.numeroPatronalID,
        documentTypeId: this.state.tipoDocumentoID,
        serverFolder: this.state.carpetaPorDefecto,
        idColaborador: this.state.usuarioData.id
      }
      if (this.state.uploadDisabled) {
        axios
          .post(URL + "lote/import/form/server", data, getAuthorizationHeader())
          .then((res) => {
            this.props.handleClose(true, res.data);
          }, error => {
            if (error.response.status === 401) {
              logout();
            }
            return error;
          });
      } else {
        this.setState({ openPantallaCarga: true });
        let formData = new FormData();
        formData.append("file", this.state.file);
        axios
          .post(URL + "recibo/upload/", formData, getAuthorizationHeaderUpload())
          .then((res) => {
            if (res.data.error == false) {
              data.file = res.data.datos;
              axios
                .post(URL + "recibo/importarDocumento", data, getAuthorizationHeader())
                .then((res) => {
                  this.props.handleClose(true, res.data);
                }, error => {
                  if (error.response.status === 401) {
                    logout();
                  }
                  return error;
                });
            }
          });
      }
    } else {
      this.setState({ errors: errors })
    }
  };


  componentDidMount() {
    axios
      .get(
        URL + "tipoDocumento/listar/" + idEmpresa(),
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ tipoDocumento: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
    axios
      .get(
        URL + "numeroPatronal/listar/" + idEmpresa(),
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ numeroPatronal: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
    axios
      .get(
        URL + "grupos/empresa/" + idEmpresa() + "/getAll",
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ gruposUsuarios: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }

  validarExtensionDocumento = () => {
    var datos = this.state.tipoDocumento;
    var extension = datos.find(tipo => tipo.id == this.state.tipoDocumentoID)
    if (extension) {
      if (extension.formato == "XML") {
        return true
      } else {
        return false
      }
    }
    return false
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: null
      },
      [name]: val,
    }))
  };

  handleChangeSelect = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    var documento = this.state.tipoDocumento.find(element => element.id == val);
    if (documento)
      this.setState({
        [name]: val,
        fileErrorMsg: "",
        formatoTipoDocumentoSeleccionado: documento.formato,
        isCarpetaPorDefecto: documento.carpetaPorDefecto != "" ? true : false,
        carpetaPorDefecto: documento.carpetaPorDefecto,
        uploadDisabled: documento.carpetaPorDefecto != "" ? true : false
      })
  }



  render() {
    return (

      <Container>
        <Form onSubmit={this.subirArchivo} noValidate>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> Nuevo Documento </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col sm>

                <Dropzone onDrop={this.onDrop} disabled={this.state.uploadDisabled}>
                  {({ getRootProps, getInputProps }) => (
                    <div>
                      <div className="carga-documento-section" style={{ border: this.state.fileErrorMsg.length == 0 ? '' : '1px solid red' }}>
                        <div className="carga-documento-content">
                          <img src={cloudUploadIcon}></img>
                          <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <p>Arrasta un archivo hasta aqui.</p>
                              <p className="text-small"> O si prefieres.. </p>
                              <a class="local-upload btn btn-lg btn-primary-outline" data-toggle="tooltip" data-placement="top"
                                data-original-title="Desktop"> Selecciona un archivo de tu dispositivo
                              </a>
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                              <p className="form-date" style={{ color: "#3eba28" }}><small>{this.state.filename}</small></p>
                            </div>
                          </section>
                        </div>
                      </div>
                      <div style={{ marginTop: "1px" }}>
                        <small style={{ color: "#f93154", alignContent: 'left' }}>{this.state.fileErrorMsg}</small>
                      </div>
                    </div>
                  )}
                </Dropzone>


              </Col>
              <Col sm>
                <p className="form-date"> <strong> Fecha de Carga:</strong> {this.state.date} </p>
                <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                  <Form.Control
                    className="form-control md-form-control"
                    name="descripcion"
                    placeholder=" "
                    rows="3"
                    onChange={this.handleInput}
                    value={this.state.descripcion}
                    isInvalid={!!this.state.errors.descripcion}
                  />
                  <Form.Label class="md-label"> Descripcion </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    <small>{this.state.errors.descripcion}</small>
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                  <Form.Control
                    as="select"
                    className="form-control md-select"
                    name="tipoDocumentoID"
                    onChange={this.handleChangeSelect}
                    value={this.state.tipoDocumentoID}
                    isInvalid={!!this.state.errors.tipoDocumentoID}>

                    <option key="" value="" selected></option>
                    {this.state.tipoDocumento.map((elemento) => (
                      <option key={elemento.id} value={elemento.id}>
                        {elemento.nombre}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label class="md-label"> Tipo de Documento: </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    <small>{this.state.errors.tipoDocumentoID}</small>
                  </Form.Control.Feedback>
                </Form.Group>

                {
                  this.state.isCarpetaPorDefecto &&
                  <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                    <Form.Control
                      className="form-control md-form-control"
                      placeholder=" "
                      name="carpetaPorDefecto"
                      variant="outlined"
                      onChange={this.handleInput}
                      value={this.state.carpetaPorDefecto}
                      isInvalid={!!this.state.errors.carpetaPorDefecto}
                    />
                    <Form.Label class="md-label"> Carpeta por defecto </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.carpetaPorDefecto}</small>
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                {
                  this.validarExtensionDocumento() &&

                  <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                    <Form.Control
                      as="select"
                      className="form-control md-select"
                      name="numeroPatronalID"
                      id="numeroPatronalID"
                      onChange={this.handleInput}
                      value={this.state.numeroPatronalID}
                      isInvalid={!!this.state.errors.numeroPatronalID}>

                      <option key="" value="" selected></option>
                      {this.state.numeroPatronal.map((elemento) => (
                        <option value={elemento.id} key={elemento.id}>{elemento.nombre}</option>
                      ))}

                    </Form.Control>
                    <Form.Label class="md-label"> Nro. MTESS patronal </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.numeroPatronalID}</small>
                    </Form.Control.Feedback>
                  </Form.Group>


                }
                <Row>
                  <Col>
                    <Form.Group className="form-group md-input" style={{ marginBottom: '30px' }}>
                      <Form.Control
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="cedula"
                        variant="outlined"
                        onChange={this.handleInput}
                        value={this.state.cedula}
                        isInvalid={!!this.state.errors.idColaborador}
                      />
                      <Form.Label class="md-label"> CI Colaborador </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <small>{this.state.errors.idColaborador}</small>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <a className="btn btn-primary" style={{ backgroundColor: "#00DAF7", marginTop: "5px" }} onClick={() => this.obtenerUsuario()}> Consultar</a>
                  </Col>
                </Row>
                <p style={{ color: "red" }}>{this.state.mensajeConsultaUsuario}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped bordered hover size="sm" style={{ marginTop: "10px" }}>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Usuario</th>
                      <th>Cedula de Identidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={this.state.usuarioData.nombreApellido}>
                      <td component="th" scope="row">
                        {this.state.usuarioData.nombreApellido}
                      </td>
                      <td>{this.state.usuarioData.usuario}</td>
                      <td>{this.state.usuarioData.cedula}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.props.handleClose(false, null)} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button color="primary" type="submit" disabled={!this.state.hayDatosUsuario}>
              Subir
            </Button>
          </Modal.Footer>
          <PantallaCarga open={this.state.openPantallaCarga} />
        </Form>
      </Container>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  guardarAplicacionExterna: (value) => dispatch(guardarAplicacionExterna(value)),
  editarAplicacionExterna: (value) => dispatch(editarAplicacionExterna(value)),
  exportarDocumento: (file) => dispatch(exportarDocumento(file)),
  getUsuario: (page, size, search) => dispatch(getUsuario(page, size, search))
});

const mapStateToProps = (state) => ({
  usuariosData: state.usuariosData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportarDocumento);

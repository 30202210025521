import React from "react";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Modal, Container, Button, Row, Card, Col, Spinner } from "react-bootstrap";
import { seleccionarPsa,seleccionTuID } from "./ProveedorFirmaService";
import default_psa from '../../images/default_psa.png';
import xCircle from '../../styles/icons/x-circle.svg'
import { TUID } from '../../constans/constants'
import { loadSignature,createGostCanvasAndGetAppareance } from "../tiposDocumento/AparienciaServicios";
import { getUsuarioLogueado } from "../login/SesionService";
import { URL, getAuthorizationHeader} from '../../constans/constants'
import { logout } from '../login/SesionService';
import axios from "axios";
import Cookies from 'universal-cookie';

class SeleccionPca extends React.Component {
  constructor(props) {
    super(props);
    if (props.listaPsa.length === 1)
      this.handleSelectPsa(props.listaPsa[0])
    else {
      this.state = {
        openPantallaCarga: false,
        idPsaSeleccionado: null,
      };
    }

  }

  handleFirmasPDF = (value) => {
    this.setState({ tipoFirma: value })
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  decodeBase64(base64data) {
    let base64ToString = Buffer.from(base64data, "base64").toString()
    return base64ToString;
  }

  async obtenerAparienciaFirmaEmpleado() {
    const cookies = new Cookies();
    try {
      const res = await axios.get(
        `${URL}lote/getEmpleadoSignFormat/${this.props.idLote}/${cookies.get('username')}`,
        getAuthorizationHeader()
      );
      
      if (res.data.error === false) {
        return res.data.datos;
      }
      return null;
  
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      return error;
    }
  }

  async obtenerDatosUsuarioLogueado() {
    try {
      const response = await getUsuarioLogueado();
      return response;
    } catch (error) {
      console.error("Error al obtener los datos del usuario logueado:", error);
      throw error;
    }
  }

  handleSelectPsa = async (elemento) => {
    this.setState({ idPsaSeleccionado: elemento.id })
    localStorage.setItem("codigoClienteFirma", elemento.id)
    localStorage.setItem("proveedorFirma", elemento.codigoCliente)
    localStorage.setItem("redirectURLFirma", (this.props.url).substring(this.props.url.indexOf("#")))
    
    if(elemento.codigoCliente != TUID){
    seleccionarPsa(elemento).then(res => {
      window.location.replace(res);
    })
  }else{
    var formatoFirmaEmpleado = await this.obtenerAparienciaFirmaEmpleado();
    var datosUsuario = await this.obtenerDatosUsuarioLogueado();
    var formatoEmpleado = null;
    if(formatoFirmaEmpleado != null){
      formatoEmpleado = await loadSignature(formatoFirmaEmpleado, datosUsuario, null, false);
    }
    var json = {
      appearance: null
    }
    if (formatoEmpleado?.appearance) {
      json = await createGostCanvasAndGetAppareance(formatoEmpleado, json, null, null, null);
    }
    seleccionTuID(elemento,this.props.idLote,this.props.idRecibo,json).then(res => {
      window.location.replace(res.datos);
    })
  }
  }

  handleClose = () => {
    this.props.handleClose();
  };

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  }

  renderButton = (elemento) => {
    if (this.state.idPsaSeleccionado != null)
      return (
        <Button onClick={() => this.handleSelectPsa(elemento)} variant="primary" disabled>
          {this.state.idPsaSeleccionado == elemento.id ?
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '5px', verticalAlign: 'middle' }}
              />
              <span style={{ verticalAlign: 'middle' }}>Cargando</span>
            </>
            :
            <span style={{ verticalAlign: 'middle' }}>{elemento.descripcion}</span>
          }

        </Button>
      )
    else
      return (
        <Button onClick={() => this.handleSelectPsa(elemento)} variant="primary">
          {elemento.descripcion}
        </Button>
      )



  }

  render() {
    if (this.props.listaPsa.length === 0)
      return (
        <Container>
          <Modal.Body>
            <div>
              <Row className="justify-content-center">
                <Col className="text-center text-md-right">
                  <img src={xCircle} style={{ margin: '40px' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="text-center text-md-right">
                  <p><strong>No se encontraron proveedores de firma activos</strong></p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Aceptar
            </Button>
          </Modal.Footer>
        </Container>
      );
    else
      return (
        <Container>
          <PantallaCarga open={this.state.openPantallaCarga} />
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> Seleccione Proveedor de Firma </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {this.props.listaPsa.map((elemento) => (
                <Card className="text-center" key={elemento.id} style={{ width: '18rem', height: '20rem' }}>
                  <Card.Body style={{ display: "flex", alignItems: "center" }}>
                    {
                      elemento.logo != null
                        ? <img className="mx-auto d-block" src={"data:image/jpeg;base64," + this.decodeBase64(elemento.logo)} />
                        : <img className="mx-auto d-block" src={default_psa} />
                    }
                  </Card.Body>
                  <Card.Footer>
                    {this.renderButton(elemento)}
                  </Card.Footer>
                </Card>
              ))}
            </Row>
          </Modal.Body>
        </Container>
      );
  }
}

export default SeleccionPca;

import axios from 'axios';
import {
  SET_TIPO_DOCUMENTO,
  SET_LOADING
} from "../../constans/action-types";
import { URL, getAuthorizationHeader, getAuthorizationHeaderUpload } from '../../constans/constants';
import Cookies from 'universal-cookie';
import { logout } from '../login/SesionService';

const cookies = new Cookies();
const defaultPage = 0;
const defaultSize = 5;
export const setTiposDocumentos = payload => ({ type: SET_TIPO_DOCUMENTO, payload });
export const setLoading = payload => ({ type: SET_LOADING, payload });

export const getTipoDocumento = (page = defaultPage, size = defaultSize, search) => {
  return dispatch => {
    var urlRequest = ""
    var idEmpresa = cookies.get('empresa');
    if ((search == null || search == '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "tipoDocumento/" + 'listar?page=' + page + "&size=" + size
    } else if ((search != null || search != '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "tipoDocumento/" + 'listar?page=' + page + "&size=" + size + "&search=" + search
    } else if ((search == null || search == '') && (idEmpresa != null)) {
      urlRequest = URL + "tipoDocumento/" + 'listar?page=' + page + "&size=" + size + "&idEmpresa=" + idEmpresa
    } else if (search != null && idEmpresa != null) {
      urlRequest = URL + "tipoDocumento/" + 'listar?page=' + page + "&size=" + size + "&search=" + search + "&idEmpresa=" + idEmpresa
    }
    return axios.get(urlRequest, getAuthorizationHeader())
      .then(res => {
        dispatch(setTiposDocumentos({
          page: res.data.page.pageable.pageNumber,
          totalCount: res.data.page.totalElements,
          tipoDocumento: res.data.page.content
        }));
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
  };
};

export const getDatosFormulario = () => {
  return axios.get(URL + "tipoDocumento/datosFormulario", getAuthorizationHeader())
    .then((res) => { return res.data}, error => {
      if (error.response.status === 401) {
       logout();
      }
      return error;
    });
}


export const getDocumento = (id) => {
  return axios.get(
    URL + "tipoDocumento/obtener/" + id,
    getAuthorizationHeader()
  )
    .then((res) => { return res.data}, error => {
      if (error.response.status === 401) {
       logout();
      }
      return error;
    });
}


export const setTipoDocumento = (data) => {
  return axios
  .post(URL + "tipoDocumento/crear", data, getAuthorizationHeader())
  .then((res) => {return res.data}, error => {
    if (error.response.status === 401) {
     logout();
    }
    return error;
  })
}

export const updateXsdFile = (file, id) => {
  return axios.post(
    URL + "tipoDocumento/importXSLFile/" + id,
    file,
    getAuthorizationHeaderUpload()
  ).then((res) => {return res.data}, error => {
    if (error.response.status === 401) {
     logout();
    }
    return error;
  })
}


export const updateTipoDocumento = (data) => {
  return axios
    .post(URL + "tipoDocumento/modificar", data, getAuthorizationHeader())
    .then((res) => {return res.data}, error => {
      if (error.response.status === 401) {
       logout();
      }
      return error;
    })
    
}


export const desactivarTipoDocumento = (id) =>{
  return axios
  .delete(URL + "tipoDocumento/eliminar/" + id, getAuthorizationHeader())
  .then((res) => {return res.data}, error => {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  })

}

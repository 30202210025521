import React from "react";
import {
  Route,
  Redirect,
  BrowserRouter,
  Switch,
  useHistory,
  useLocation,
  HashRouter
} from "react-router-dom";
import PropTypes from "prop-types";
import Navigator from "./Navigation";
import Header from "./Header";
import Login from "../login/LoginKeicloack";
import LoginAdmin from "../login/Login";
import PublicRoute from "../Routes/PublicRoute"
import PrivateRoute from "../Routes/PrivateRoute"
import Empresas from "../empresas/Empresas"
import tipoDocumento from "../tiposDocumento/TiposDocumentos"
import numeroPatronal from "../numeroPatronal/NumerosPatronales"
import aplicacionExterna from "../aplicacionExterna/AplicacionesExterna"
import usuarios from "../usuario/Usuarios"
import configuracion from "../correo/Configuracion"
import plantillaCorreo from "../correo/PlantillaCorreo"
import lotesNuevos from "../lotes/LotesNuevos"
import lotesFirmados from "../lotes/LotesFirmados"
import lotesPublicar from "../lotes/LotesPublicar"
import lotesPublicados from "../lotes/LotesPublicados"
import lotesCerrados from "../lotes/LotesCerrados"
import recibosPendientes from "../recibos/RecibosPendientes"
import recibosConfirmados from "../recibos/RecibosConfirmados"
import recibosFirmados from "../recibos/RecibosFirmados"
import recibosRechazados from "../recibos/RecibosRechazados"
import reportesEmpleados from "../reportes/ReportesEmpleados"
import reporteRecibos from "../reportes/ReporteRecibos"
import recibos from "../recibos/Recibos"
import { connect } from 'react-redux';
import axios from "axios";
import { authenticateLoginCliente, logged, loggedAux, obtenerURL } from '../login/SesionService'
import { URL, logueado, administradorEmpresas, gestorEmpresa, empleado, iscertLoguin, firmaRL, firmaRRHH, reportes } from '../../constans/constants';
import pdfVisor from '../documentViewer/VistaPdf'
import NuevoTipoDocumento from "../tiposDocumento/NuevoTipoDocumento";
import reciboEmpleado from "../recibos/ReciboEmpleado"
import reportesFirmados from "../reportes/ReportesFirmados"
import reportesRechazados from "../reportes/ReportesRechazados"
import reportesPendientes from "../reportes/ReportesPendientes"
import VistaPdf from "../documentViewer/VistaPdf";
import { logout } from "../login/SesionService";
import ReportesConfirmados from "../reportes/ReportesConfirmados";
import superAdminForm from "../home/SuperAdmin"
import GruposUsuarios from "../usuario/GruposUsuarios";
import proveedoresFirma from "../pca/ProveedoresFirma";
import ActualizarContrasenha from "../usuario/ActualizarPasswrd";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import { Row, Col, Modal, Button } from "react-bootstrap";
import checkCircle from '../../styles/icons/check-circle.svg'
import xCircle from '../../styles/icons/x-circle.svg'

export function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

function Home(props) {
  const history = useHistory();
  const location = useLocation();
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mensajeAlerta, setMensajeAlerta] = React.useState("Contraseña Modificada con exito");
  const [severity, setSeverity] = React.useState("success");
  const [mensajeOpen, setMensajeOpen] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const redirectURLFirma = localStorage.getItem("redirectURLFirma");

  if (iscertLoguin) {
    if (!logueado() && location.pathname != '/admin/login') {
      if (getParameterByName('token') === "") {
        axios.get(URL + "auth").then((res) => {
          window.location.replace(res.data);
        }, error => {
          if (error.response.status === 401) {
            logout();
          }
          return error;
        });
      } else if (!logueado()) {
        const token = getParameterByName('token');
        const username = getParameterByName('username');
        const admin = false;
        props.authenticateLoginCliente({
          token: token,
          username: username,
          admin: admin
        });
        window.history.pushState('', '', '/mirecibo/#')
      }
    }
  } else {
    if (getParameterByName('token') != "") {
      localStorage.setItem("tokenIscert", getParameterByName('token'))
      if (redirectURLFirma != null) {
        localStorage.removeItem("redirectURLFirma")
        window.history.pushState('', '', '/mirecibo/' + redirectURLFirma)
      } else {
        window.history.pushState('', '', '/mirecibo/#')
      }
    }

    if (getParameterByName('status_tuid') != "") {
      localStorage.setItem("status_tuid", getParameterByName('status_tuid'))
      if (redirectURLFirma != null) {
        localStorage.removeItem("redirectURLFirma")
        window.history.pushState('', '', '/mirecibo/' + redirectURLFirma)
      } else {
        window.history.pushState('', '', '/mirecibo/#')
      }
    }
    
    if (getParameterByName('kc_action_status') != "") {
      if (getParameterByName('kc_action_status') == "success") {
        setMensajeOpen(!mensajeOpen)
        window.history.pushState('', '', '/mirecibo/#')
      } else if (getParameterByName('kc_action_status') != "cancelled") {
        setSeverity("danger")
        setMensajeAlerta("Se produjo un error al intentar modificar la contraseña")
        setMensajeOpen(!mensajeOpen)
        window.history.pushState('', '', '/mirecibo/#')
      }
    }
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const cerrarMensaje = () => {
    setMensajeOpen(!mensajeOpen)
  }
  return (
    <HashRouter basename="">
      <Switch>
        <PublicRoute restricted={true} path="/login" component={Login} />
        <PublicRoute restricted={true} path="/admin/login" component={LoginAdmin} />
        <PublicRoute restricted={true} path="/admin/registro" component={superAdminForm} />
        {!iscertLoguin && <Redirect exact from="/" to="/login" />}
        <Redirect exact from="/mirecibo" to="/recibos/pendientes" />
        <Redirect exact from="/" to="/recibos/pendientes" />
        <Route
          render={() => {
            return (
              <div className="wrapperPage">
                <Navigator />
                <div className="main">
                  <nav className="navbar navbar-light">
                    <Header />
                  </nav>
                  {/* <Header onDrawerToggle={handleDrawerToggle} /> */}
                  <main>
                    <Modal
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={mensajeOpen}
                      backdrop="static"
                      keyboard="false"
                    >
                      <Modal.Body>
                        {
                          <div>
                            <Row className="justify-content-center">
                              <Col className="text-center text-md-right">
                                {severity !== "success"
                                  ? <img src={xCircle} style={{ margin: '40px' }}></img>
                                  : <img src={checkCircle} style={{ margin: '40px' }}></img>
                                }
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col className="text-center text-md-right">
                                <p><strong>{mensajeAlerta}</strong></p>
                              </Col>
                            </Row>
                          </div>
                        }
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={cerrarMensaje}>
                          Aceptar
                        </Button>
                      </Modal.Footer>

                    </Modal>

                    <Switch>
                      <PrivateRoute path="/admin/empresas" component={Empresas} exact />
                      <PrivateRoute path="/admin/configuracion/tipo-documento" component={tipoDocumento} exact />
                      <PrivateRoute path="/admin/configuracion/tipo-documento/nuevo" rol={[gestorEmpresa]} component={NuevoTipoDocumento} exact />
                      <PrivateRoute path="/admin/configuracion/numero-patronal" component={numeroPatronal} exact />
                      <PrivateRoute path="/admin/configuracion/aplicaciones-externas" component={aplicacionExterna} exact />
                      <PrivateRoute path="/admin/proveedores-firma" component={proveedoresFirma} exact />
                      <PrivateRoute path="/admin/usuarios" component={usuarios} exact />
                      <PrivateRoute path="/notificacion/configuracion-correo" component={configuracion} exact />
                      <PrivateRoute path="/admin/notificacion/plantilla-correo" component={plantillaCorreo} exact />
                      <PrivateRoute path="/administracion/usuarios" rol={[administradorEmpresas]} component={usuarios} exact />
                      <PrivateRoute path="/administracion/usuarios/actualizar-password" component={ActualizarContrasenha} exact />
                      <PrivateRoute path="/administracion/grupos" rol={[administradorEmpresas]} component={GruposUsuarios} exact />
                      <PrivateRoute path="/configuracion/aplicaciones-externas" rol={[gestorEmpresa]} component={aplicacionExterna} exact />
                      <PrivateRoute path="/configuracion/tipo-documento" rol={[gestorEmpresa]} component={tipoDocumento} exact />
                      <PrivateRoute path="/configuracion/numero-patronal" rol={[gestorEmpresa]} component={numeroPatronal} exact />
                      <PrivateRoute path="/lote/nuevos" rol={[gestorEmpresa, firmaRRHH]} component={lotesNuevos} exact />
                      <PrivateRoute path="/lote/firmados" rol={[gestorEmpresa, firmaRL]} component={lotesFirmados} exact />
                      <PrivateRoute path="/lote/publicar" rol={[gestorEmpresa]} component={lotesPublicar} exact />
                      <PrivateRoute path="/lote/publicados" rol={[gestorEmpresa]} component={lotesPublicados} exact />
                      <PrivateRoute path="/lote/cerrados" rol={[gestorEmpresa]} component={lotesCerrados} exact />
                      <PrivateRoute path="/lote/recibos/:estado/:idLote" rol={[gestorEmpresa, firmaRL, firmaRRHH]} component={recibos} exact />
                      <PrivateRoute path="/lote/recibos/visor" component={pdfVisor} exact />
                      <PrivateRoute path="/admin/notificacion/configuracion-correo" component={configuracion} exact />
                      <PrivateRoute path="/configuracion/plantilla-correo" rol={gestorEmpresa} component={plantillaCorreo} exact />
                      <PrivateRoute path="/bandeja/pendientes" rol={[empleado]} component={recibosPendientes} exact />
                      <PrivateRoute path="/bandeja/confirmados" rol={[empleado]} component={recibosConfirmados} exact />
                      <PrivateRoute path="/bandeja/firmados" rol={[empleado]} component={recibosFirmados} exact />
                      <PrivateRoute path="/bandeja/rechazados" rol={[empleado]} component={recibosRechazados} exact />
                      <PrivateRoute path="/reporte/recibo-colaborador/todos" rol={[gestorEmpresa, reportes]} component={reportesEmpleados} exact />
                      <PrivateRoute path="/reporte/:colaborador/recibos/:id/estado/:estado" rol={[gestorEmpresa, reportes]} component={reporteRecibos} exact />
                      <PrivateRoute path="/reporte/:colaborador/recibos/:id" rol={[gestorEmpresa, reportes]} component={reporteRecibos} exact />
                      <PrivateRoute path="/reporte/recibo-colaborador/firmados" rol={[gestorEmpresa, reportes]} component={reportesFirmados} exact />
                      <PrivateRoute path="/reporte/recibo-colaborador/pendientes" rol={[gestorEmpresa, reportes]} component={reportesPendientes} exact />
                      <PrivateRoute path="/reporte/recibo-colaborador/rechazados" rol={[gestorEmpresa, reportes]} component={reportesRechazados} exact />
                      <PrivateRoute path="/reporte/recibo-colaborador/aceptados" rol={[gestorEmpresa, reportes]} component={ReportesConfirmados} exact />
                      <PrivateRoute path="/recibo/view/:estado/:idLote/:idRecibo" rol={[empleado]} component={reciboEmpleado} exact />
                      <PrivateRoute path="/bandeja/recibo/view/:estado/:idLote/:idRecibo" rol={[empleado]} component={reciboEmpleado} exact />
                      <PrivateRoute path="/recibo/:recibo/lote/:lote/ver" component={VistaPdf} exact />
                      <PrivateRoute path="/reporte/nro-recibo/:recibo/nro-lote/:lote/ver" component={VistaPdf} exact />
                      <PrivateRoute path="/bandeja/nro-recibo/:recibo/nro-lote/:lote/ver" component={VistaPdf} exact />
                    </Switch>
                  </main>
                </div>
              </div>
            );
          }}
        />
      </Switch>
    </HashRouter>
  );
}

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No hay resultados para el enlace: <code>{location.pathname}</code>
    </h3>
  </div>
);
Home.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  authenticateLoginCliente: (value) => dispatch(authenticateLoginCliente(value)),
  logged: () => dispatch(logged())
});
export default connect(null, mapDispatchToProps)(Home);

import axios from 'axios';
import {
    SET_APLICACION_EXTERNA,
    SET_LOADING
} from "../../constans/action-types";
import {URL,getAuthorizationHeader} from '../../constans/constants'
import Cookies from 'universal-cookie';
import { logout } from '../login/SesionService';


  const cookies = new Cookies();
  const defaultPage = 0;
  const defaultSize = 5;
  export const setAplicacionExterna = payload => ({ type: SET_APLICACION_EXTERNA, payload });
  export const setLoading = payload => ({ type: SET_LOADING, payload });

  export const getAplicacionesExternas = (page = defaultPage,size = defaultSize, search) => {
    return dispatch => {
      var urlRequest = ""
      var idEmpresa = cookies.get('empresa');
      if((search === null || search === '') && (idEmpresa === null || idEmpresa === undefined)){
        urlRequest = URL+"aplicacionesExternas/" + 'listar?page=' + page+"&size=" + size
      }else if((search !== null || search !== '') && (idEmpresa === null || idEmpresa === undefined)){
        urlRequest = URL+"aplicacionesExternas/" + 'listar?page=' + page+"&size=" + size +"&search=" + search
      }else if((search === null || search === '') && (idEmpresa != null)){
        urlRequest = URL+"aplicacionesExternas/" + 'listar?page=' + page+"&size=" + size +"&idEmpresa="+idEmpresa
      }else if(search != null && idEmpresa != null){
        urlRequest = URL+"aplicacionesExternas/" + 'listar?page=' + page+"&size=" + size +"&search=" + search+"&idEmpresa="+idEmpresa
      }
      return axios.get(urlRequest,getAuthorizationHeader())
        .then(res => {
          dispatch(setAplicacionExterna({
            page: res.data.page.pageable.pageNumber,
            totalCount: res.data.page.totalElements,
            aplicacionExterna: res.data.page.content
          }));
        }, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        });
    };
  };

  export const eliminarAplicacionExterna = (idAplicacionExterna) =>{
    var urlRequest = URL+"aplicacionesExternas/borrar/"+idAplicacionExterna
    return axios.delete(urlRequest,getAuthorizationHeader())
      .then(res => {
        return res.data;
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      });
  }

  export const guardarAplicacionExterna = (aplicacionExterna) => {
    return dispatch => { 
      //var idEmpresa = cookies.get('empresa')
      dispatch(setLoading(true));
      const json = {
        nombre: aplicacionExterna.aplicacion,
        descripcion: aplicacionExterna.descripcion,
        empresaId: aplicacionExterna.idEmpresa,
        url: aplicacionExterna.url,
        pwd: aplicacionExterna.password,
        usuario: aplicacionExterna.usuario
      };
      return axios.post(URL+ 'aplicacionesExternas/agregar', json,getAuthorizationHeader())
        .then(response => {
          if(response.data.error){
            dispatch(setLoading(false));
              return response.data
          }else{
            dispatch(setLoading(false));
            return response.data
        }}, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        });
  };
  }
  export const editarAplicacionExterna = (aplicacionExterna) => {
    return dispatch => { 
      //var idEmpresa = cookies.get('empresa')
      dispatch(setLoading(true));
      const json = {
        nombre: aplicacionExterna.aplicacion,
        descripcion: aplicacionExterna.descripcion,
        empresaId: aplicacionExterna.idEmpresa,
        url: aplicacionExterna.url,
        pwd: aplicacionExterna.password,
        usuario: aplicacionExterna.usuario
      };
      return axios.post(URL+ 'aplicacionesExternas/modificar/'+aplicacionExterna.id, json,getAuthorizationHeader())
        .then(response => {
          if(response.data.error){
            dispatch(setLoading(false));
            return response.data
          }else{
            dispatch(setLoading(false));
           return response.data
          }
        }, error => {
          if (error.response.status === 401) {
           logout();
          }
          return error;
        });
      }
  }
import axios from 'axios';
import {
    SET_PROVEEDORES,
    SET_LOADING
} from "../../constans/action-types";
import { URL, getAuthorizationHeader, getAuthorizationHeaderUpload } from '../../constans/constants'
import { logout } from '../login/SesionService';

const defaultPage = 0;
const defaultSize = 5;
export const setProveedores = payload => ({ type: SET_PROVEEDORES, payload });
export const setLoading = payload => ({ type: SET_LOADING, payload });


export const getProveedoresFirmas = (page = defaultPage, size = defaultSize, search = "") => {
    return dispatch => {
        return axios.get(URL + "pca/listar?page=" + page + "&size=" + size + "&search=" + search, getAuthorizationHeader())
            .then(res => {
                dispatch(setProveedores({
                    page: res.data.page.pageable.pageNumber,
                    totalCount: res.data.page.totalElements,
                    proveedores: res.data.page.content
                }));
            }, error => {
                if (error.response.status === 401) {
                    logout();
                }
                return error;
            });
    };
};


export const crearNuevoProveedor = (data) => {
    return axios.post(URL + "pca/crear", data, getAuthorizationHeader())
        .then(response => {
            return response.data
        }, error => {
            if (error.response.status === 401) {
                logout();
            }
            return error;
        });
}

export const desactivarProveedor = (idProveedor) => {
    return axios.delete(URL + "pca/desactivar/" + idProveedor, getAuthorizationHeader())
        .then(res => {
            return res.data;
        }, error => {
            if (error.response.status === 401) {
                logout();
            }
            return error;
        });
}

export const editarProveedor = (data) => {
    return axios.post(URL + 'pca/editar/', data, getAuthorizationHeader())
        .then(response => {
            return response.data;
        }, error => {
            if (error.response.status === 401) {
                logout();
            }
            return error;
        });
}

export const cargarImagen = (id) => {
    return axios.get(URL + "pca/cargarImagen/" + id, getAuthorizationHeader())
        .then(res => {
            return res.data;
        }, error => {
            if (error.response.status === 401) {
                logout();
            }
            return error;
        });
}

export const subirImagen = (id, formData) => {
    return axios.post(URL + "pca/subirImagen/" + id, formData, getAuthorizationHeaderUpload())
}

export const inicioSesionEfimra = (psaID, data) => {
    return axios.post(URL + "payrolls/efirma/token?psaID=" + psaID, data, getAuthorizationHeader())
        .then(response => { return response.data })
}

export const seleccionarPsa = (data) => {
    return axios.get(URL + "auth/" + data.id, getAuthorizationHeader())
        .then(response => { return response.data })
}
export const seleccionTuID = (data,idLote,idRecibo,json) => {
    return axios.post(URL + "recibo/firmar/tuID/"+idLote+"/"+idRecibo+"/"+ data.id,json, getAuthorizationHeader())
        .then(response => { return response.data })
}
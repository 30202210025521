import React from "react";
import { connect } from "react-redux";
import {
  guardarAplicacionExterna,
  editarAplicacionExterna,
} from "../../components/aplicacionExterna/AplicacionExternaService";
import { idEmpresa, admin } from "../../constans/constants";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap"; 
import { validateForm } from "../../constans/validationUtils";

class AplicacionExterna extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aplicacion: "",
      descripcion: "",
      url: "",
      usuario: "",
      pwd: "",
      errors:{
        aplicacion: null,
        descripcion: null,
        url: null,
        usuario: null,
        pwd: null,
        empresa: null,
        listaEmpresaSelected: null
      },
      id: "",
      mensaje: "",
      severity: "",
      openPantallaCarga: false,
      validated: false,
      listaEmpresas: props.listaEmpresa,
      listaEmpresaSelected: "",
    };
  }

  reglasValidacionCampos = {
    aplicacion: [
      { required: true, message: 'Ingrese el nombre de la aplicacion externa' },
    ],
    descripcion: [
      { required: true, message: 'Ingrese una descripcion corta' },
    ],
    url: [
      {
        customValidator: (value, formData) => {
          return value.trim().length > 0
        }, message: 'Ingrese una direccion URL valida'
      },
    ],
    usuario: [
      { required: true, message: 'Ingrese el nombre de usuario para la aplicacion externa' },
 
    ],
    pwd : [
      { required: true, message: 'Ingrese una contraseña para la aplicacion externa' },
 
    ],
    listaEmpresaSelected: [
      {
        customValidator: (value, formData) => {
          if(!admin())
            return true
          else 
            return value > 0
        }, message: 'Debe seleccionar una empresa'
      },
    ]
  }

  handleClose = () => {
    this.props.handleClose(false, this.state.mensaje, this.state.severity);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleAplicacionExternaSubmit = event => {
    event.preventDefault();
    const { isValid, errors } = validateForm(this.state, this.reglasValidacionCampos);
    if (isValid) {
      this.setState({ openPantallaCarga: true });
      if (this.props.type === "edit") {
        const json = {
          aplicacion: this.state.aplicacion,
          descripcion: this.state.descripcion,
          url: this.state.url,
          usuario: this.state.usuario,
          password: this.state.pwd,
          id: this.state.id,
          idEmpresa: this.props.isAdmin ? this.state.listaEmpresaSelected : idEmpresa()
        };
        this.props.editarAplicacionExterna(json).then((response) => {
          this.setState({
            mensaje: response.mensaje,
            severity: !response.error ? "success" : "danger"
          });
          this.handleClose();
        });
      } else if (this.props.type === "add") {
        const json = {
          aplicacion: this.state.aplicacion,
          descripcion: this.state.descripcion,
          url: this.state.url,
          usuario: this.state.usuario,
          password: this.state.pwd,
          idEmpresa: this.props.isAdmin ? this.state.listaEmpresaSelected : idEmpresa()
        };
        this.props.guardarAplicacionExterna(json).then((response) => {
          this.setState({
            mensaje: response.mensaje,
            severity: !response.error ? "success" : "danger"
          });
          this.handleClose();
        });
      }
    } else {
      this.setState({errors: errors});
    }
  };

  componentDidMount() {
    if (this.props.type === "edit") {
      this.setState({ 
        aplicacion: this.props.aplicacionExterna.aplicacion,
        descripcion: this.props.aplicacionExterna.descripcion,
        url: this.props.aplicacionExterna.url,
        usuario: this.props.aplicacionExterna.usuario,
        pwd: this.props.aplicacionExterna.password,
        id: this.props.aplicacionExterna.id,
        listaEmpresaSelected: this.props.aplicacionExterna.empresa.id
      });
    }
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: null
      },
      [name]: val,
    }))
  };

  handleChangeSelect = (e) => {
    let val = e.target.value
    let name = e.target.name
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: null
      },
      [name]: val,
    }))

  }

  render() {
    return (
      <Container fluid>
        <Form ref="form" noValidate validated={this.state.validated} onSubmit={this.handleAplicacionExternaSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}>
              {" "}
              {this.props.dialogTitle}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {
            (this.props.isAdmin && this.props.listaEmpresas != null) ?
              <Row>
              <Col xs={6} style={{ paddingTop: "10px" }}>
                <Form.Group className="form-group md-input">
                    <Form.Control
                      as="select" 
                      className="form-control md-select" 
                      name="listaEmpresaSelected" 
                      id="listaEmpresaSelected"
                      onChange={this.handleChangeSelect} 
                      value={this.state.listaEmpresaSelected}
                      isInvalid={!!this.state.errors.listaEmpresaSelected}>

                      <option key="" value="" selected></option>

                      {this.props.listaEmpresas.map((elemento) => (
                        <option value={elemento.id} key={elemento.id}>{elemento.nombre}</option>
                      ))}
                    </Form.Control>
                  <Form.Label class="md-label"> Seleccione una empresa </Form.Label>
                  <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.listaEmpresaSelected}</small>
                    </Form.Control.Feedback>
                  </Form.Group>
              </Col>
              <Col xs={6} style={{ paddingTop: "10px" }}>
              <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="aplicacion"
                      id="aplicacion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.aplicacion}
                      isInvalid={!!this.state.errors.aplicacion}
                    />
                    <Form.Label class="md-label"> Nombre Aplicación </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.aplicacion}</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            :
            <Row>
              <Col style={{ padding: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="aplicacion"
                      id="aplicacion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.aplicacion}
                      isInvalid={!!this.state.errors.aplicacion}
                    />
                    <Form.Label class="md-label"> Nombre Aplicación </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.aplicacion}</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            }
            <Row>
              <Col style={{ padding: "10px", marginTop: "15px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="descripcion"
                      id="descripcion"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                      isInvalid={!!this.state.errors.descripcion}
                    />
                    <Form.Label class="md-label"> Descripción </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.descripcion}</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <Form.Control
                      className="form-control md-form-control"
                      type="url"
                      name="url"
                      id="url"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.url}
                      isInvalid={!!this.state.errors.url}
                    />
                    <Form.Label class="md-label"> URL </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>Campo requerido</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} style={{ paddingTop: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <Form.Control
                      className="form-control md-form-control"
                      type="text"
                      name="usuario"
                      id="usuario"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.usuario}
                      isInvalid={!!this.state.errors.usuario}
                    />
                    <Form.Label class="md-label"> Usuario </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.usuario}</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col xs={6} style={{ paddingTop: "10px" }}>
                <Form.Group className="form-group md-input">
                  <div className="form-group md-input">
                    <Form.Control
                      className="form-control md-form-control"
                      type="password"
                      name="pwd"
                      id="pwd"
                      placeholder=" "
                      onChange={this.handleInput}
                      value={this.state.pwd}
                      isInvalid={!!this.state.errors.pwd}
                    />
                    <Form.Label class="md-label"> Contraseña </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.pwd}</small>
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit">
              {this.props.submitText}
            </Button>
          </Modal.Footer>
        </Form>
        <PantallaCarga open={this.state.openPantallaCarga} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  guardarAplicacionExterna: (value) =>
    dispatch(guardarAplicacionExterna(value)),
  editarAplicacionExterna: (value) => dispatch(editarAplicacionExterna(value)),
});

export default connect(null, mapDispatchToProps)(AplicacionExterna);

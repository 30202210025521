import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

const PublicRoute = ({ sessionData, component: Component, restricted, ...rest }) => {
  const cookies = new Cookies();
  var logged = (cookies.get('logged') === "true");
  var admin = (cookies.get('admin') === "true");
  const ruta = () =>{
    if(admin){
      return "/admin/usuarios"
    }else{
      return "/bandeja/pendientes"
    }
  }

  return (
    <Route {...rest} render={props => (
      logged && restricted ?
        <Redirect to={ruta()} />
        : <Component {...props} />
    )} />
  ); 
};

const mapStateToProps = state => ({
  sessionData: state.sessionData
});

export default connect(mapStateToProps)(PublicRoute);
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  SET_USUARIO,
  SET_LOADING,
  SET_GRUPOS
} from "../../constans/action-types";
import { URL, getAuthorizationHeader, getAuthorizationHeaderUpload } from '../../constans/constants';
import { logout } from '../login/SesionService';

const cookies = new Cookies();
const defaultPage = 0;
const defaultSize = 5;
export const setUsuarios = payload => ({ type: SET_USUARIO, payload });
export const setLoading = payload => ({ type: SET_LOADING, payload });
export const setGrupos = payload => ({ type: SET_GRUPOS, payload });

export const getUsuario = (page = defaultPage, size = defaultSize, search) => {
  return dispatch => {
    var urlRequest = ""
    var idEmpresa = cookies.get('empresa');
    if ((search == null || search == '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "usuario/listar?page=" + page + "&size=" + size
    } else if ((search != null || search != '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "usuario/listar?page=" + page + "&size=" + size + "&search=" + search
    } else if ((search == null || search == '') && (idEmpresa != null)) {
      urlRequest = URL + "usuario/listar?page=" + page + "&size=" + size + "&idEmpresa=" + idEmpresa
    } else if (search != null && idEmpresa != null) {
      urlRequest = URL + "usuario/listar?page=" + page + "&size=" + size + "&search=" + search + "&idEmpresa=" + idEmpresa
    }
    return axios.get(urlRequest, getAuthorizationHeader())
      .then(res => {
        dispatch(setUsuarios({
          page: res.data.page.pageable.pageNumber,
          totalCount: res.data.page.totalElements,
          usuarios: res.data.page.content
        }));
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  };
};

export const subirArchivoCsv = (file, idEmpresa) => {
  return dispatch => {
    dispatch(setLoading(true));
    return axios.post(URL + 'usuario/' + idEmpresa + '/importar', file, getAuthorizationHeaderUpload())
      .then(response => {
        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      })
  }
}

export const subirAparienciaFirma = (file, username) => {
  return axios.post(URL + 'usuario/aparienciaFirma/' + username, file, getAuthorizationHeaderUpload())
    .then(response => {
      return response.data;
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    })
}

export const getAparienciaFirma = (username) => {
    return axios.get(URL + "usuario/aparienciaFirma/" + username, getAuthorizationHeader())
      .then(response =>{
        return response.data
      })
}

export const registrarUsuario = (usuario, idEmpresa = -200) => {
  return dispatch => {
    dispatch(setLoading(true));
    const json = {
      username: usuario.usuario,
      name: usuario.nombres,
      lastname: usuario.apellidos,
      userId: usuario.identificador,
      cedula: usuario.cedula,
      phoneNumber: usuario.celular,
      email: usuario.email,
      roles: usuario.rolesxempresa,
      nroPersonalIps: usuario.nroPersonalIps,
      nroTrabajoMtess: usuario.nroTrabajoMtess,
      empresaId: usuario.empresaId
    };
    return axios.post(URL + 'usuario/registrar/' + idEmpresa, json, getAuthorizationHeader())
      .then(response => {
        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }
}

export const obtenerRolesEmpresa = (idUsuario) => {
  return axios
    .get(
      URL + "usuario/obtenerRolesUsuarioEdit/" + idUsuario,
      getAuthorizationHeader()
    )
    .then((res) => {
      return res.data
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    })
}

export const obtenerRolesEmpresaDatos = (idUsuario) => {
  return axios
    .get(
      URL + "usuario/obtenerRolesPorUsuarioDatos/" + idUsuario,
      getAuthorizationHeader()
    )
    .then((res) => {
      return res.data
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    })
}

export const editarUsuario = (usuario) => {
  return dispatch => {
    dispatch(setLoading(true));
    const json = {
      username: usuario.usuario,
      name: usuario.nombres,
      lastname: usuario.apellidos,
      userId: usuario.identificador,
      cedula: usuario.cedula,
      phoneNumber: usuario.celular,
      email: usuario.email,
      roles: usuario.rolesxempresa,
      nroPersonalIps: usuario.nroPersonalIps,
      nroTrabajoMtess: usuario.nroTrabajoMtess
    };
    return axios.post(URL + 'usuario/editar/' + usuario.id, json, getAuthorizationHeader())
      .then(response => {
        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }
}
/*-------------Grupos----------------*/
export const getGrupos = (page = defaultPage, size = defaultSize, search) => {
  return dispatch => {
    var urlRequest = ""
    var idEmpresa = cookies.get('empresa');
    if ((search == null || search == '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "grupos/listar?page=" + page + "&size=" + size
    } else if ((search != null || search != '') && (idEmpresa == null || idEmpresa == undefined)) {
      urlRequest = URL + "grupos/listar?page=" + page + "&size=" + size + "&search=" + search
    } else if ((search == null || search == '') && (idEmpresa != null)) {
      urlRequest = URL + "grupos/listar?page=" + page + "&size=" + size + "&idEmpresa=" + idEmpresa
    } else if (search != null && idEmpresa != null) {
      urlRequest = URL + "grupos/listar?page=" + page + "&size=" + size + "&search=" + search + "&idEmpresa=" + idEmpresa
    }
    return axios.get(urlRequest, getAuthorizationHeader())
      .then(res => {
        dispatch(setGrupos({
          page: res.data.page.pageable.pageNumber,
          totalCount: res.data.page.totalElements,
          grupos: res.data.page.content
        }));
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  };
};

export const registrarGrupo = (json) => {
  return dispatch => {
    dispatch(setLoading(true));
    return axios.post(URL + 'grupos/registrar', json, getAuthorizationHeader())
      .then(response => {
        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        dispatch(setLoading(false));
        return error;
      });
  }
}

export const editarGrupo = (json) => {
  return dispatch => {
    dispatch(setLoading(true));
    return axios.post(URL + 'grupos/editar', json, getAuthorizationHeader())
      .then(response => {
        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }
}

export const eliminarGrupo = (id) => {
  return dispatch => {
    dispatch(setLoading(true));
    return axios.delete(URL + 'grupos/eliminar/' + id, getAuthorizationHeader())
      .then(response => {
        dispatch(setLoading(false));
        return response.data;
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }
}

export const actualizaPassword = () => {
  return axios.get(URL + 'usuario/keycloak/cambiar-passwrd', getAuthorizationHeader())
    .then(response => {
      if (!response.data.error) {
        window.location.href = response.data.datos
      }
    })
}

export const verificarDatos = (usuario) => {
  const json = {
    username: usuario.usuario,
    name: usuario.nombres,
    lastname: usuario.apellidos,
    userId: usuario.identificador,
    cedula: usuario.cedula,
    phoneNumber: usuario.celular,
    email: usuario.email,
    roles: usuario.rolesxempresa,
    nroPersonalIps: usuario.nroPersonalIps,
    nroTrabajoMtess: usuario.nroTrabajoMtess
  };
  return axios.post(URL + 'usuario/verificarDatos', json, getAuthorizationHeader())
    .then(response => {
      return response.data;
    }, error => {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    });

}

export const obtenerDatosUsuario = (username) => {
  return axios.get(URL + 'usuario/obtenerDatosColaborador/username/' + username, getAuthorizationHeader())
    .then(response =>{
      return response.data
    })
}


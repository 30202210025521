import React from "react";
import { connect } from "react-redux";
import {
  guardarAplicacionExterna,
  editarAplicacionExterna,
} from "../../components/aplicacionExterna/AplicacionExternaService";
import {
  URL,
  getAuthorizationHeader,
  idEmpresa,
  getAuthorizationHeaderUpload,
} from "../../constans/constants";
import { exportarArchivo } from "../lotes/LoteService";
import axios from "axios";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { logout } from "../login/SesionService";
import { validateForm } from "../../constans/validationUtils";
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import cloudUploadIcon from '../../styles/icons/cloud_upload.svg';

class ImportarLote extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
    this.state = {
      descripcion: "",
      numeroPatronal: [],
      gruposUsuarios: [],
      tipoDocumento: [],
      lote: [],
      id: "",
      date: date,
      file: null,
      uploadDisabled: false,
      numeroPatronalID: "",
      tipoDocumentoID: "",
      idGrupo: "",
      isCarpetaPorDefecto: false,
      carpetaPorDefecto: "",
      fileErrorMsg: "",
      path: "",
      mensaje: "",
      severity: "",
      mostrarMensajeFlash: false,
      submit: false,
      openPantallaCarga: false,
      requiredNroMtess: false,
      grupoCheckBox: false,
      errors: {
        descripcion: null,
        numeroPatronalID: null,
        tipoDocumentoID: null,
        idGrupo: null,
        carpetaPorDefecto: null
      }
    };

    this.onChangeFile = this.onChangeFile.bind(this);
  }

  reglasValidacionCampos = {
    descripcion: [
      { required: true, message: 'Debe proveer una descripcion para el lote' },
    ],
    tipoDocumentoID: [
      {
        customValidator: (value, formData) => {
          return value > 0
        }, message: 'Debe seleccionar un tipo de documento'
      },
    ],
    numeroPatronalID: [
      {
        customValidator: (value, formData) => {
          if (!this.validarExtensionDocumento())
            return true
          else
            return value > 0
        }, message: 'Debe elegir una opcion'
      },
    ],
    carpetaPorDefecto: [
      {
        customValidator: (value, formData) => {
          if (!this.state.isCarpetaPorDefecto)
            return true
          else
            return /^(\/[^\/]+)+$/.test(value)
        }, message: 'Ingrese una direccion valida'
      },
    ],
    idGrupo: [
      {
        customValidator: (value, formData) => {
          if (!this.state.grupoCheckBox)
            return true
          else
            return value > 0
        }, message: 'Debe seleccionar un grupo'
      },
    ]
  }

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleDialogImport = (value) => {
    this.setState({
      resultImportPopUp: value
    })
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({
      file: event.target.files[0],
      size: event.target.files[0].size,
      filename: event.target.files[0].name,
      fileErrorMsg: ""
    });
  }

  onDrop = (files) => {
    this.setState({
      file: files[0],
      filename: files[0].name,
      fileErrorMsg: ''
    });
  }

  handleChecked = (event) => {
    var name = event.target.name;
    var val = event.target.checked;
    this.setState({
      [name]: val,
      idGrupo: ""
    });
  }

  checkFileExtension = () => {
    if (this.state.file == null)
      return "";
    var fileName = this.state.file.name
    var extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    if (extension === "zip")
      return "zip";
    return extension;
  }

  subirArchivo = event => {
    event.preventDefault();
    const { isValid, errors } = validateForm(this.state, this.reglasValidacionCampos);
    const extensionArchivo = this.checkFileExtension();
    if (isValid ) {
      const data = {
        description: this.state.descripcion,
        file: null,
        filename: this.state.filename,
        empresaId: idEmpresa(),
        idGrupo: this.state.idGrupo,
        numeroPatronalId: this.state.numeroPatronalID,
        documentTypeId: this.state.tipoDocumentoID,
        serverFolder: this.state.carpetaPorDefecto
      }
      if (this.state.uploadDisabled) {
        axios
          .post(URL + "lote/import/form/server", data, getAuthorizationHeader())
          .then((res) => {
            this.props.handleClose(true, res.data);
          }, error => {
            if (error.response.status === 401) {
              logout();
            }
            return error;
          });
      } else {
        if(extensionArchivo == "zip"){
          this.setState({ openPantallaCarga: true });
          let formData = new FormData();
          formData.append("file", this.state.file);
          axios
            .post(URL + "lote/upload/", formData, getAuthorizationHeaderUpload())
            .then((res) => {
              if (res.data.error == false) {
                data.file = res.data.datos;
                axios
                  .post(URL + "lote/import/form/client", data, getAuthorizationHeader())
                  .then((res) => {
                    this.props.handleClose(true, res.data);
                  }, error => {
                    if (error.response.status === 401) {
                      logout();
                    }
                    return error;
                  });
              }
            });
        }else{
          this.setState({
          fileErrorMsg: "*Debe subir un archivo en formato .zip",
          errors: errors,
          submit: true,
          file: null
          })
        }
      }
    } else {
      if (extensionArchivo != "zip")
        this.setState({
          fileErrorMsg: "*Debe subir un archivo en formato .zip",
          errors: errors,
          submit: true,
          file: null
        })
      else
        this.setState({ errors: errors })
    }
  };


  componentDidMount() {
    axios
      .get(
        URL + "tipoDocumento/listar/" + idEmpresa(),
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ tipoDocumento: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
    axios
      .get(
        URL + "numeroPatronal/listar/" + idEmpresa(),
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ numeroPatronal: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
    axios
      .get(
        URL + "grupos/empresa/" + idEmpresa() + "/getAll",
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ gruposUsuarios: res.data.datos });
        }
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  }

  validarExtensionDocumento = () => {
    var datos = this.state.tipoDocumento;
    var extension = datos.find(tipo => tipo.id == this.state.tipoDocumentoID)
    if (extension) {
      if (extension.formato == "XML") {
        return true
      } else {
        return false
      }
    }
    return false
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: null
      },
      [name]: val,
    }))
  };

  handleChangeSelect = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    var documento = this.state.tipoDocumento.find(element => element.id == val);
    if (documento)
      this.setState({
        [name]: val,
        isCarpetaPorDefecto: documento.carpetaPorDefecto != "" ? true : false,
        carpetaPorDefecto: documento.carpetaPorDefecto,
        uploadDisabled: documento.carpetaPorDefecto != "" ? true : false
      })
  }



  render() {
    return (

      <Container>
        <Form onSubmit={this.subirArchivo} noValidate>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> Nuevo lote de recibos </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col xs={6} md={6}>

                <Dropzone onDrop={this.onDrop} disabled={this.state.uploadDisabled}>
                  {({ getRootProps, getInputProps }) => (
                    <div>
                      <div className="carga-documento-section" style={{ border: this.state.fileErrorMsg.length == 0 ? '' : '1px solid red' }}>
                        <div className="carga-documento-content">
                          <img src={cloudUploadIcon}></img>
                          <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <p>Arrasta un archivo hasta aqui.</p>
                              <p className="text-small"> O si prefieres.. </p>
                              <a class="local-upload btn btn-lg btn-primary-outline" data-toggle="tooltip" data-placement="top"
                                data-original-title="Desktop"> Selecciona un archivo de tu dispositivo
                              </a>
                            </div>
                            <div style={{ marginBottom: "3px" }}>
                              <small>{this.state.filename}</small>
                            </div>

                          </section>
                        </div>
                      </div>
                      <div style={{ marginTop: "1px" }}>
                        <small style={{ color: "#f93154", alignContent: 'left' }}>{this.state.fileErrorMsg}</small>
                      </div>
                    </div>

                  )}
                </Dropzone>


              </Col>
              <Col xs={6} md={6}>
                <p className="form-date"> <strong> Fecha de Carga:</strong> {this.state.date} </p>
                <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                  <div className="form-group md-input">
                    <Form.Control
                      as="textarea"
                      className="form-control md-form-control"
                      name="descripcion"
                      placeholder=" "
                      rows="3"
                      onChange={this.handleInput}
                      value={this.state.descripcion}
                      isInvalid={!!this.state.errors.descripcion}
                    />
                    <Form.Label class="md-label"> Descripcion </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.descripcion}</small>
                    </Form.Control.Feedback>
                  </div>

                </Form.Group>
                <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                  <Form.Control
                    as="select"
                    className="form-control md-select"
                    name="tipoDocumentoID"
                    onChange={this.handleChangeSelect}
                    value={this.state.tipoDocumentoID}
                    isInvalid={!!this.state.errors.tipoDocumentoID}>

                    <option key="" value="" selected></option>
                    {this.state.tipoDocumento.map((elemento) => (
                      <option key={elemento.id} value={elemento.id}>
                        {elemento.nombre}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label class="md-label"> Tipo de Documento: </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    <small>{this.state.errors.tipoDocumentoID}</small>
                  </Form.Control.Feedback>
                </Form.Group>

                {
                  this.state.isCarpetaPorDefecto &&
                  <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                    <div className="form-group md-input">
                      <Form.Control
                        className="form-control md-form-control"
                        type="text"
                        placeholder=" "
                        name="carpetaPorDefecto"
                        value={this.state.carpetaPorDefecto}
                        variant="outlined"
                        onChange={this.handleInput}
                        isInvalid={!!this.state.errors.carpetaPorDefecto}
                      />
                      <Form.Label class="md-label"> Carpeta por defecto </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        <small>{this.state.errors.carpetaPorDefecto}</small>
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                }

                {
                  this.validarExtensionDocumento() &&

                  <Form.Group className="form-group md-input" style={{ marginBottom: '40px' }}>
                    <Form.Control
                      as="select"
                      className="form-control md-select"
                      name="numeroPatronalID"
                      id="numeroPatronalID"
                      onChange={this.handleInput}
                      value={this.state.numeroPatronalID}
                      isInvalid={!!this.state.errors.numeroPatronalID}>

                      <option key="" value="" selected></option>
                      {this.state.numeroPatronal.map((elemento) => (
                        <option value={elemento.id} key={elemento.id}>{elemento.nombre}</option>
                      ))}

                    </Form.Control>
                    <Form.Label class="md-label"> Nro. MTESS patronal </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.numeroPatronalID}</small>
                    </Form.Control.Feedback>
                  </Form.Group>


                }

                {
                  this.state.grupoCheckBox &&

                  <Form.Group className="form-group md-input" style={{ marginBottom: '30px' }}>
                    <Form.Control
                      as="select"
                      className="form-control md-select"
                      name="idGrupo"
                      id="idGrupo"
                      onChange={this.handleInput}
                      value={this.state.idGrupo}
                      isInvalid={!!this.state.errors.idGrupo}>

                      <option key="" value="" selected></option>
                      {this.state.gruposUsuarios.map((elemento) => (
                        <option value={elemento.idGrupo} key={elemento.idGrupo}>{elemento.descripcion}</option>
                      ))}

                    </Form.Control>
                    <Form.Label class="md-label"> Seleccione un grupo </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      <small>{this.state.errors.idGrupo}</small>
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                <Form.Group className="form-group md-input">
                  <Form.Check
                    type="checkbox"
                    label="Envio grupal de documentos" name="grupoCheckBox"
                    onChange={this.handleChecked}
                    checked={this.state.grupoCheckBox} />
                </Form.Group>

              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.props.handleClose(false, null)} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Importar lote
            </Button>
          </Modal.Footer>
          <PantallaCarga open={this.state.openPantallaCarga} />
        </Form>
      </Container>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  guardarAplicacionExterna: (value) => dispatch(guardarAplicacionExterna(value)),
  editarAplicacionExterna: (value) => dispatch(editarAplicacionExterna(value)),
  exportarArchivo: (file) => dispatch(exportarArchivo(file))
});

export default connect(null, mapDispatchToProps)(ImportarLote);

export const validateForm = (formData, validationRules) => {
  let isValid = true;
  const errors = {};

  for (const fieldName in validationRules) {
    if (validationRules.hasOwnProperty(fieldName)) {
      const rules = validationRules[fieldName];
      const value = String(formData[fieldName]).trim();

      for (const rule of rules) {
        if (rule.required && !value) {
          errors[fieldName] = rule.message;
          isValid = false;
          break;
        }
        if (rule.pattern && !rule.pattern.test(value)) {
          errors[fieldName] = rule.message;
          isValid = false;
          break;
        }
        if (rule.customValidator && !rule.customValidator(value, formData)) {
          errors[fieldName] = rule.message;
          isValid = false;
          break;
        }
      }
    }
  }

  return { isValid, errors };
};

  
import React from "react";
import axios from "axios";
import { URL, getAuthorizationHeader } from "../../constans/constants";
import { firmaEmpleado } from './RecibosService';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { getUsuarioLogueado, getEmpresa, logout } from "../../components/login/SesionService"
import { Container, Form, Modal, Button } from 'react-bootstrap'
import Cookies from 'universal-cookie';
import { createGostCanvasAndGetAppareance, loadSignature } from "../tiposDocumento/AparienciaServicios";

class PinEmpleado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idLote: "",
      keystore: 0,
      formatoFirmaEmpleado: "",
      pin: "",
      respuesta: "",
      severity: "success",
      mostrarPantallaCarga: false,
      datosUsuarios: {},
      datosEmpresa: {},
      isFirmaEmpleado: false,
      validated: false,
      isLoaded: false
    };
  }

  handleClose = (data) => {
    this.props.handleClose(false, data);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleFirmasPDF = (tipoFirma, value) => {
    if (tipoFirma == 'EMP')
      this.setState({ isFirmaEmpleado: value })
  };


  handlePinSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true })
    } else {
      this.setState({ openPantallaCarga: true });
      if (this.props.formatoDocumento == "PDF") {
        var formatoEmpleado = await loadSignature(this.state.formatoFirmaEmpleado, this.state.datosUsuarios, this.state.datosEmpresa, false);
        var apariencia = null;
        var json = {
          appearance: null,
          keystoreId: this.state.keystore,
          pin: this.state.pin,
          idLote: this.props.idLote,
          idRecibo: this.props.idRecibo
        };
        if (formatoEmpleado.appearance) {
          json = await createGostCanvasAndGetAppareance(formatoEmpleado, json, this.props.estado, this.handleClose, this.handleFirmasPDF);
          await this.firmar(json);
        }
      } else {
        const json = {
          appearance: null,
          keystoreId: this.state.keystore,
          pin: this.state.pin,
          idLote: this.props.idLote,
          idRecibo: this.props.idRecibo
        };
        this.setState({ mostrarPantallaCarga: true });
        firmaEmpleado(json).then((response) => {
          this.handleClose(response);
        });
      }
    }
  };

  firmar = async (json)=>{
    if(this.props.estado == 'Pendientes'){
       firmaEmpleado(json).then((response) => {
        this.handleClose(response)
        return response;
      });
    }
  }

  componentDidMount() {
    const cookies = new Cookies();
    var token = {token:localStorage.getItem("tokenIscert"),
    psa:localStorage.getItem("codigoClienteFirma")}
    axios
      .post(URL + "payrolls/iscert/keystore",token,getAuthorizationHeader())
      .then((res) => {
        if (res.data.error == false) {
          this.setState({ keystore: res.data.datos.keystores[0].id, isLoaded: true });
        } else {
          this.setState({
            severity: "danger",
            respuesta: res.data.mensaje
          });
          this.handleClose(res.data);
        }
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      })
    axios
      .get(
        URL + "lote/getEmpleadoSignFormat/" + this.props.idLote + "/"+cookies.get('username'),
        getAuthorizationHeader()
      )
      .then((res) => {
        if (res.data.error == false)
          this.setState({ formatoFirmaEmpleado: res.data.datos });
      }, error => {
        if (error.response.status === 401) {
         logout();
        }
        return error;
      })
    getUsuarioLogueado().then((response) => {
      this.setState({ datosUsuarios: response });
    });
    getEmpresa().then((response) => {
      this.setState({ datosEmpresa: response })
    });
  }

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
  };
  render() {
    return (
      <Container>
        <PantallaCarga open={this.state.openPantallaCarga} />
        <Form noValidate validated={this.state.validated} onSubmit={this.handlePinSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#0c56d0" }}> {this.props.dialogTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="datosLotes">
              <p className="form-date">
              {" "}
              <strong>Confirmar datos:</strong>
              </p>
              <div className="lote-date"> Lote: {this.props.loteRecibo} | fecha: {this.props.fecha} </div>
              <div className="lote-row">
                <p className="lote-text"> <strong>Concepto: </strong> {this.props.concepto} </p>
              </div>
            </div>
            <p className="form-date"> <strong> Ingresa el Pin de Firma para firmar el recibo seleccionado:</strong> </p>
            <Form.Group className="form-group md-input">
              <input
                className="form-control md-form-control"
                type="password"
                name="pin"
                onChange={this.handleInput}
                value={this.state.pin}
                required
              />
              <Form.Label class="md-label"> Pin de firma </Form.Label>
              <Form.Control.Feedback type="invalid">
                <small>Campo requerido</small>
              </Form.Control.Feedback>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.handleClose(null)} variant="contained" color="primary">
              Cancelar
            </Button>
            <Button autoFocus color="primary" type="submit" disabled={!this.state.isLoaded}>
              Firmar
            </Button>
          </Modal.Footer>
        </Form>
      </Container>
    );
    //}

  }
}

export default PinEmpleado;

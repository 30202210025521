import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getAplicacionesExternas, eliminarAplicacionExterna } from "../aplicacionExterna/AplicacionExternaService";
import "../../styles/Empresa.css";
import AplicacionExternaDialogContent from "../aplicacionExterna/AplicacionExterna";
import Navbar from "../home/Navbar";
import AplicacionExternaDatos from "./AplicacionExternaDatos";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import { esSesionAdmin } from "../usuario/Usuario";
import {obtenerListaEmpresas} from "../empresas/EmpresaService";
import delete_forever from '../../styles/icons/delete.svg';
import edit from '../../styles/icons/edit.svg';
import visibility from '../../styles/icons/visibility.svg';
import ExclamacionIcon from '../../styles/icons/exclamation-circle.svg'

class aplicacionesExternas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Registrar nueva aplicacion externa",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedAplicacionExterna: {
        aplicacion: "",
        descripcion: "",
        url: "",
        usuario: "",
        pwd: "",
        id: "",
        idEmpresa: ""
      },
      mostrarMensaje: false,
      mensaje: "",
      severity: "",
      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      openPantallaCarga: false,
      listaEmpresas: null,
      listaEmpresaSelected: null
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    if (esSesionAdmin()) {
      obtenerListaEmpresas().then((res) => {
        this.setState({
          listaEmpresas: res.datos,
          listaEmpresaSelected: res.datos[0].id
      })
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  handleDialog = (value) => {
    this.setState({
      dialogIsOpen: value
    });
  };
  handleDialogClose = (value, mensaje = "", severity) => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
      if (mensaje !== "") {
        this.setState({
          mostrarMensaje: true,
          mensaje: mensaje,
          severity: severity
        });
      }
    }
    this.setState({
      dialogIsOpen: value
    });
  };
  handleNewEmpresa = () => {
    this.setState(
      {
        dialogTitle: "Registrar nueva Aplicacion Externa",
        selectedAplicacionExterna: {
          aplicacion: "",
          descripcion: "",
          url: "",
          usuario: "",
          pwd: "",
          id: ""
        },
        open: false,
        dialogType: "add",
        submitText: "Guardar",
        disabledInput: false,
      },
      () => {
        this.handleDialog(true);
      }
    );
  };
  handleDialogVerDatos = (value) => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
    }
    this.setState({
      dialogIsOpenVerDatos: value,
    });
  };
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensaje: false,
    });
  };

  eliminarAplicacionExterna = () => {
    this.setState({ openPantallaCarga: true });
    this.handleClose();
    eliminarAplicacionExterna(this.state.selectedAplicacionExterna.id).then((respuesta) => {
      this.setState({ openPantallaCarga: false });
      if (respuesta.error) {
        this.setState({
          mostrarMensaje: true,
          mensaje: respuesta.mensaje,
          severity: "danger"
        })
        this.tableRef.current.onQueryChange();
      } else {
        this.setState({
          mostrarMensaje: true,
          mensaje: respuesta.mensaje,
          severity: "success"
        })
        this.tableRef.current.onQueryChange();
      }
    });
  }

  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <div>
              <PantallaCarga open={this.state.openPantallaCarga} />
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.open}
                onHide={this.handleClose}
                backdrop="static"
                keyboard="false"
              >
                <Container>
                  <Modal.Body>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                        <img src={ExclamacionIcon} alt='' style={{margin: '40px'}}></img>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col className="text-center text-md-right">
                        <p><strong>Estas Seguro que desea eliminar la Aplicacion Externa?</strong></p>
                      </Col>
                    </Row>
                    <Row style={{ background: "#EDF2F9" }}>
                      <Col>
                        <p><strong>Aplicación:</strong> {this.state.selectedAplicacionExterna.aplicacion}</p>
                        <p><strong>Descripción: </strong>{this.state.selectedAplicacionExterna.descripcion}</p>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.handleClose} variant="contained">Cancelar</Button>
                    <Button onClick={this.eliminarAplicacionExterna} color="primary" autoFocus>
                      Aceptar
                    </Button>
                  </Modal.Footer>
                </Container>
              </Modal>

              <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.dialogIsOpenVerDatos}
                onHide={this.handleDialogVerDatos}
                keyboard="false">
                <AplicacionExternaDatos
                  aplicacionExterna={this.state.selectedAplicacionExterna}
                  handleClose={this.handleDialogVerDatos}
                  type={this.state.dialogType}
                  submitText={this.state.submitText}
                  disabledInput={this.state.disabledInput}
                  dialogTitle='Datos de aplicacion externa'
                />
              </Modal>

              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.dialogIsOpen}
                onHide={this.handleDialogClose}
                keyboard="false">
                <AplicacionExternaDialogContent
                  aplicacionExterna={this.state.selectedAplicacionExterna}
                  handleClose={this.handleDialogClose}
                  type={this.state.dialogType}
                  submitText={this.state.submitText}
                  disabledInput={this.state.disabledInput}
                  dialogTitle={this.state.dialogTitle}
                  isAdmin={esSesionAdmin()}
                  listaEmpresas={this.state.listaEmpresas}
                  listaEmpresaSelected={this.state.selectedAplicacionExterna.idEmpresa}
                />

              </Modal>
              <MensajeFlash
                mensaje={this.state.mensaje}
                open={this.state.mostrarMensaje}
                severity={this.state.severity}
                handleClose={this.handleCloseMensajeFlash} />
              <div className="sectionHeader">
                <h1 className="pageTitle"> Aplicaciones Externas </h1>
                <div className="actions">
                  <Button className="btn btn-primary" onClick={this.handleNewEmpresa}> Nueva Aplicación Externa  </Button>
                </div>
              </div>
            </div>
            
            <Navbar
              menuActual={window.location.hash}
              seccionActual={ !esSesionAdmin() ? 'configuracion' : 'administracion'}
            />
          

            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                style={{ borderRadius: ".5rem" }}
                title="Aplicaciones Externas"
                tableRef={this.tableRef}
                columns={[
                  { title: "Aplicación ", field: "aplicacion" },
                  { title: "Descripción", field: "descripcion" },
                  { title: "URL", field: "url" },
                  { title: "Api Key", field: "apiKey" },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    this.props.getAplicacionesExternas(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.aplicacionExternaData.aplicacionExterna,
                        page: this.props.aplicacionExternaData.page,
                        totalCount: this.props.aplicacionExternaData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  {
                    icon: () => <img src={edit} alt="Editar"/>,
                    tooltip: 'Editar Aplicación Externa',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Editar Aplicación Externa",
                        selectedAplicacionExterna: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleDialog(true);
                      });
                    }
                  },

                  {
                    icon: () => <img src={visibility} alt="Ver"/>,
                    tooltip: 'Ver',
                    onClick: (event, rowData) => {
                      ;
                      this.setState({
                        dialogTitle: "Ver Aplicación Externa",
                        selectedAplicacionExterna: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleDialogVerDatos(true);
                      });
                    }
                  },

                  {
                    icon: () => <img src={delete_forever} alt="Eliminar"/>,
                    tooltip: 'Eliminar',
                    onClick: (event, rowData) => {
                      ;
                      this.setState({
                        dialogTitle: "Ver Aplicación Externa",
                        selectedAplicacionExterna: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleClickOpen();
                      });
                    }
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold"
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAplicacionesExternas: (page, size, search) => dispatch(getAplicacionesExternas(page, size, search)),
});

const mapStateToProps = (state) => ({
  aplicacionExternaData: state.aplicacionExternaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(aplicacionesExternas);

import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getTipoDocumento } from "../tiposDocumento/TipoDocumentoService";
import Navbar from '../home/Navbar';
import "../../styles/Empresa.css";
import DocumentDialogContent from "../tiposDocumento/NuevoTipoDocumento"
import TiposDocumentoDatos from "../tiposDocumento/TiposDocumentoDatos";
import { desactivarTipoDocumento } from "../tiposDocumento/TipoDocumentoService";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import "../../scripts.jsx"
import { sassTrue } from "sass";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { esSesionAdmin } from "../usuario/Usuario";
import delete_forever from '../../styles/icons/delete.svg';
import edit from '../../styles/icons/edit.svg';
import visibility from '../../styles/icons/visibility.svg';
import settings_backup_restore from '../../styles/icons/settings_backup_restore.svg';
import ExclamacionIcon from '../../styles/icons/exclamation-circle.svg'

class TiposDocumentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Registrar nuevo Tipo de Documento",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedTipoDocumento: {
        nombre: "",
        formato: "",
        firmaEmpresa: true,
        acciones: "",
        id: "",
      },
      nuevoTipoDocumento: false,
      editarTipoDocumento: false,
      verTipoDocumento: false,
      eliminarTipoDocumento: false,
      restaurarTipoDocumento: false,
      mostrarMensajeFlash: false,
      mensajeFlash: "",
      mensajeFlashSeverity: "success",
      openPantallaCarga: false
    };
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  handleOpenNuevoTipoDocumento = () => {
    this.setState({ nuevoTipoDocumento: true });
  }

  handleCloseNuevoTipoDocumento = (mensaje = "", severity = "") => {
    this.tableRef.current.onQueryChange();
    if (mensaje != "") {
      this.setState({
        mostrarMensajeFlash: true,
        mensajeFlash: mensaje,
        mensajeFlashSeverity: severity
      });
    }
    this.setState({ nuevoTipoDocumento: false })

  }

  handleOpenEditarTipoDocumento = () => {
    this.setState({ editarTipoDocumento: true });
  }

  handleCloseEditarTipoDocumento = (mensaje = "", severity = "") => {
    if (mensaje != "") {
      this.setState({
        mostrarMensajeFlash: true,
        mensajeFlash: mensaje,
        mensajeFlashSeverity: severity
      });
    }
    this.setState({ editarTipoDocumento: false })
    this.tableRef.current.onQueryChange();
  }

  handleDialogVerDatos = (value) => {
    this.setState({
      verTipoDocumento: value,
    });
  };

  handleDialogEliminarDocumento = (value) => {
    this.setState({
      eliminarTipoDocumento: value
    });
  }

  handleDialogRestaurarDocumento = (value) => {
    this.setState({
      restaurarTipoDocumento : value
    });
  }

  handleDelete = () => {
    this.setState({
      openPantallaCarga: true,
      eliminarTipoDocumento: false
    });
    desactivarTipoDocumento(this.state.selectedTipoDocumento.id).then(res => {
      this.setState({
        openPantallaCarga: false,
        open: false,
        mostrarMensajeFlash: true,
        mensajeFlash: res.mensaje
      });
      if (!res.error)
        this.setState({ mensajeFlashSeverity: "success" })
      else
        this.setState({ mensajeFlashSeverity: "danger" })

      this.tableRef.current.onQueryChange();
    })
  }


  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };


  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <PantallaCarga open={this.state.openPantallaCarga} />

            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.eliminarTipoDocumento}
              onHide={() => this.handleDialogEliminarDocumento(false)}
              backdrop="static"
              keyboard="false"
            >
              <Container>
                <Modal.Body>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                    <img src={ExclamacionIcon} style={{margin: '40px'}}></img>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <p><strong>¿Está seguro que desea eliminar el documento seleccionado?</strong></p>
                    </Col>
                  </Row>
                  <Row style={{ background: "#EDF2F9" }}>
                    <Col style={{marginTop: '15px'}}>
                      <p><strong>Nombre de Documento: </strong>{this.state.selectedTipoDocumento.nombre}</p>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.handleDialogEliminarDocumento(false)} variant="contained">Cancelar</Button>
                  <Button onClick={this.handleDelete} color="primary" autoFocus>
                    Aceptar
                  </Button>
                </Modal.Footer>
              </Container>
            </Modal>

            <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.editarTipoDocumento}
              onHide={this.handleCloseEditarTipoDocumento}
              backdrop="static"
              keyboard="false"
            >
              <DocumentDialogContent
                id={this.state.selectedTipoDocumento.id}
                handleClose={this.handleCloseEditarTipoDocumento}
                type="edit"
                submitText={this.state.submitText}
                disabledInput={this.state.disabledInput}
                dialogTitle="Editar documento"
              />

            </Modal>

            <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.nuevoTipoDocumento}
              onHide={this.handleCloseNuevoTipoDocumento}
              backdrop="static"
              keyboard="false"
            >
              <DocumentDialogContent
                id={this.state.selectedTipoDocumento.id}
                handleClose={this.handleCloseNuevoTipoDocumento}
                type="add"
                submitText={this.state.submitText}
                disabledInput={this.state.disabledInput}
                dialogTitle="Nuevo tipo documento"
              />

            </Modal>

            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.verTipoDocumento}
              onHide={() => this.handleDialogVerDatos(false)}
              backdrop="static"
              keyboard="false"
            >
              <TiposDocumentoDatos
                id={this.state.selectedTipoDocumento.id}
                disabledInput={this.state.disabledInput}
                dialogTitle="Ver documento"
              />
            </Modal>


            <MensajeFlash
              mensaje={this.state.mensajeFlash}
              severity={this.state.mensajeFlashSeverity}
              open={this.state.mostrarMensajeFlash}
              handleClose={this.handleCloseMensajeFlash} />

            <div className="sectionHeader">
              <h1 className="pageTitle"> Tipos Documentos </h1>
              {
                !esSesionAdmin() &&
                <div className="actions">
                  <a className="btn btn-primary" onClick={this.handleOpenNuevoTipoDocumento}> Nuevo Documento </a>
                </div>
              }

            </div>

            <Navbar
              menuActual={window.location.hash}
              seccionActual={!esSesionAdmin() ? 'configuracion' : 'administracion'}
            />
            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                style={{ borderRadius: ".5rem" }}
                title=''
                tableRef={this.tableRef}
                columns={
                  !esSesionAdmin() ?
                  [
                  {
                    title: "Nombre", field: "nombre", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Formato de importacion", field: "formato", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Acciones del Colaborador", field: "tipoFirmaEmpleado", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Firma de Empresa", field: "firmaEmpresa", type: "boolean", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                ]
                :
                [
                  {
                    title: "Nombre", field: "nombre", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Formato de importacion", field: "formato", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Empresa", field: "empresa", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Activo", field: "activo", type: "boolean", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    this.props.getTipoDocumento(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.tipoDocumentoData.tipoDocumento,
                        page: this.props.tipoDocumentoData.page,
                        totalCount: this.props.tipoDocumentoData.totalCount,
                      });
                    });
                  })
                }
                actions={
                  !esSesionAdmin() ?
                    [
                      {
                        icon: () => <img src={edit} alt="Editar" />,
                        tooltip: 'Editar Tipo de Documento',
                        onClick: (event, rowData) => {
                          this.setState({
                            selectedTipoDocumento: rowData,
                            dialogType: "edit",
                            disabledInput: false,
                          }, () => {
                            this.setState({ mostrarMensajeFlash: false });
                            this.handleOpenEditarTipoDocumento();
                          });
                        }
                      },

                      {
                        icon: () => <img src={visibility} alt="Ver"/>,
                        tooltip: 'Ver',
                        onClick: (event, rowData) => {
                          this.setState({
                            dialogTitle: "Ver tipo de Documento",
                            selectedTipoDocumento: rowData,
                            dialogType: "ver",
                            submitText: "Ver",
                            disabledInput: false
                          }, () => {
                            this.handleDialogVerDatos(true);
                          });
                        }
                      },

                      {
                        icon: () => <img src={delete_forever} alt="Eliminar"/>,
                        tooltip: 'Eliminar',
                        onClick: (event, rowData) => {
                          ;
                          this.setState({
                            selectedTipoDocumento: rowData,
                            dialogType: "edit",
                            submitText: "Editar",
                            disabledInput: false
                          }, () => {
                            this.handleDialogEliminarDocumento(true);
                          });
                        }
                      },
                    ]
                    :
                    [
                      {
                        icon: () => <img src={visibility} alt="Ver"/>,
                        tooltip: 'Ver',
                        onClick: (event, rowData) => {
                          this.setState({
                            dialogTitle: "Ver tipo de Documento",
                            selectedTipoDocumento: rowData,
                            dialogType: "ver",
                            submitText: "Ver",
                            disabledInput: false
                          }, () => {
                            this.handleDialogVerDatos(true);
                          });
                        }
                      },
                      {
                        icon: () => <img src={delete_forever} alt="Eliminar"/>,
                        tooltip: 'Eliminar',
                        onClick: (event, rowData) => {
                          ;
                          if(rowData.activo){
                            this.setState({
                              selectedTipoDocumento: rowData,
                              dialogType: "edit",
                              submitText: "Editar",
                              disabledInput: false
                            }, () => {
                              this.handleDialogEliminarDocumento(true);
                            });
                          } 
                        },
                      },
                      {
                        icon: () => <img src={settings_backup_restore} alt="Restaurar"/>,
                        tooltip: 'Restaurar',
                        onClick: (event, rowData) => {
                          ;
                          if(!rowData.activo){
                            this.setState({
                              selectedTipoDocumento: rowData,
                              dialogType: "edit",
                              submitText: "Editar",
                              disabledInput: false
                            }, () => {
                              this.handleDialogRestaurarDocumento(true);
                            });
                          }

                        }
                      },
                    ]
                }
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: sassTrue,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold"
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTipoDocumento: (page, size, search) => dispatch(getTipoDocumento(page, size, search)),
});

const mapStateToProps = (state) => ({
  tipoDocumentoData: state.tipoDocumentoData,
});

export default connect(mapStateToProps, mapDispatchToProps)(TiposDocumentos);
import React from "react";
import { connect } from "react-redux";
import {
  editarEmpresa,
  guardarEmpresa,
} from "../../components/empresas/EmpresaService";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { obtenerIdEmpresa, esSesionAdmin } from "../usuario/Usuario";
import {
  getTipoEventos,
  getPlantillas,
  guardarPlantilla
} from "../../components/correo/CorreoService";

import Navbar from "../home/Navbar";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Form, Row, Col } from 'react-bootstrap';


class PlantillaCorreo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idPlantilla: 0,
      tipoEventos: [],
      tipoEvento: "",
      wildcards: [],
      responderA: "",
      asunto: "",
      cuerpo: "<p></p>",
      plantillas: [],
      mensajeFlash: "",
      mostrarMensajeFlash: false,
      mensajeFlashSeverity: "danger",
      openPantallaCarga: false
    };
  }

  componentDidMount() {
    const empresa = esSesionAdmin() ? -200 : obtenerIdEmpresa();
    getTipoEventos(empresa).then(response => {
      if (!response.error) {
        this.setState({ tipoEventos: response.datos });
      } else {
        this.setState({
          mostrarMensajeFlash: true,
          mensajeFlash: response.mensaje,
          mensajeFlashSeverity: "danger"
        });
      }
    });
    getPlantillas(empresa).then(response => {
      if (!response.error) {
        this.setState({ plantillas: response.datos });
      } else {
        this.setState({
          mostrarMensajeFlash: true,
          mensajeFlash: response.mensaje,
          mensajeFlashSeverity: "danger"
        });
      }
    });
  }

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ openPantallaCarga: true });
    const json = {
      id: this.state.idPlantilla,
      tipoId: this.state.tipoEvento,
      responderA: this.state.responderA,
      asunto: this.state.asunto,
      empresaId: obtenerIdEmpresa(),
      cuerpo: this.state.cuerpo
    };

    guardarPlantilla(json).then(response => {
      if (!response.error) {
        this.setState({
          mensajeFlashSeverity: "success"
        });
      }
      this.setState({
        mensajeFlash: response.mensaje,
        mostrarMensajeFlash: true,
        openPantallaCarga: false
      });
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "tipoEvento") {
      this.setState({
        wildcards: this.state.tipoEventos
          .find(elemento => elemento.id === event.target.value)
          .wildcards,
      });
      var plantilla = this.state.plantillas
        .find(elemento => elemento.tipoId === event.target.value);
      if (typeof plantilla === "undefined") {
        plantilla = {
          responderA: "",
          asunto: "",
          cuerpo: "<p></p>"
        };
        this.setState({ idPlantilla: 0 });
      } else {
        this.setState({ idPlantilla: plantilla.id });
      }
      this.setState({
        responderA: plantilla.responderA,
        asunto: plantilla.asunto,
        cuerpo: plantilla.cuerpo
      });
    }
  }

  render() {
    return (
      <div className="dashboardContent">
        <div className="container-fluid">
          <MensajeFlash
            mensaje={this.state.mensajeFlash}
            severity={this.state.mensajeFlashSeverity}
            open={this.state.mostrarMensajeFlash}
            handleClose={this.handleCloseMensajeFlash} />
          <div className="sectionHeader">
            <h1 className="pageTitle"> Plantillas de Correo </h1>
          </div>

          <Navbar
            menuActual={window.location.hash}
            seccionActual={'configuracion'}
          />
          <div className="contentPage card">
            <Form className="" id="formPlantillaCorreo" onSubmit={this.handleSubmit}>

              <div className="form-content">
                <Row>
                  <Col xs={6}>
                    <div className="form-col">
                      <Form.Group className="form-group md-input">
                        <Form.Select
                          className="form-control md-select"
                          name="tipoEvento"
                          onChange={this.handleChange}
                          value={this.state.tipoEvento}>
                          <option value=""> </option>
                          {this.state.tipoEventos.map((evento) => (
                            <option key={evento.id} value={evento.id}>
                              {evento.nombre}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Label className="md-label"> Tipo de Evento <span>*</span> </Form.Label>
                      </Form.Group>

                      <Form.Group className="form-group md-input">
                        <input className="form-control md-form-control" type="email" placeholder=" " name="responderA"
                          onChange={this.handleChange} value={this.state.responderA} />
                        <Form.Label className="md-label">Responder a <span>*</span></Form.Label>
                      </Form.Group>

                      <Form.Group className="form-group md-input">
                        <input className="form-control md-form-control" type="text" placeholder=" " label="Asunto"
                          name="asunto"
                          onChange={this.handleChange}
                          value={this.state.asunto} />
                        <Form.Label className="md-label">Asunto <span>*</span></Form.Label>
                      </Form.Group>

                      <Form.Group className="form-group md-input">
                        <CKEditor
                          name="cuerpo"
                          editor={ClassicEditor}
                          data={this.state.cuerpo}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            this.setState({
                              cuerpo: editor.getData()
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="form-col border">
                      <div className="select-note">
                        <p className="txt-note">
                          <strong> NOTA </strong>
                          <br />Estos son los posibles parámetros a incluir en el cuerpo del mensaje:
                        </p>
                        <ul className="list-note">
                          {this.state.wildcards
                            .map(elemento => (
                              <li>{elemento}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                  <div className="actons-btn" style={{ padding: '10px' }}>
                    <button type="submit" className="btn btn-primary"> GUARDAR </button>
                  </div>
                </Col>
              </Row>
            </Form>
            <PantallaCarga open={this.state.openPantallaCarga} />
          </div>
        </div>
      </div>
    );

  }
}

const mapDispatchToProps = (dispatch) => ({
  guardarEmpresa: (value) => dispatch(guardarEmpresa(value)),
  editarEmpresa: (value) => dispatch(editarEmpresa(value)),
});

export default connect(null, mapDispatchToProps)(PlantillaCorreo);
import React from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getLotes, eliminarLote } from "./LoteService";
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import "../../styles/Empresa.css";
import { Link } from "react-router-dom";
import { Modal, Row, Col, Container, Button } from 'react-bootstrap';
import Navbar from "../home/Navbar";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import visibility from '../../styles/icons/visibility.svg';
import deleteIcon from '../../styles/icons/delete.svg';
import ExclamacionIcon from '../../styles/icons/exclamation-circle.svg';


class LotesPublicar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecibo: {
        nombre: "",
        fechaImportacion: "",
        descripcion: "",
        cantDocumentos: "",
        tipoDocumento: "",
        estado: "",
        estadoMTESS: false
      },
      dialogEliminarLote: false,
      mostrarMensajeFlash: false,
      openPantallaCarga: false
    };
    localStorage.setItem("urlRaiz", window.location.href);
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  handleDialogEliminarLote = (value) => {
    this.setState({
      dialogEliminarLote: value,
    });
  };

  handleEliminarLote = () => {
    this.setState({openPantallaCarga: true, dialogEliminarLote: false})
    eliminarLote(this.state.selectedRecibo.id).then(response => {
      if (response.error) {
        this.setState({
          mensajeFlashSeverity: "danger",
          mensajeFlash: response.mensaje,
          mostrarMensajeFlash: true,
          openPantallaCarga: false
        });
      } else {
        this.setState({
          mensajeFlash: "Lote y recibos asociados eliminados",
          mensajeFlashSeverity: "success",
          mostrarMensajeFlash: true,
          openPantallaCarga: false
        });
      }
      this.handleDialogEliminarLote(false);
      this.tableRef.current.onQueryChange();
    })
  }

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });
  };

  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
          <PantallaCarga open={this.state.openPantallaCarga} />
          <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.dialogEliminarLote}
              onHide={() => this.handleDialogEliminarLote(false)}
              backdrop="static"
              keyboard="false"
            >
              <Container>
                <Modal.Body>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <img src={ExclamacionIcon} style={{margin: '40px'}}></img>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <p><strong>Estas Seguro que desea eliminar este lote y sus recibos asociados?</strong></p>
                    </Col>
                  </Row>
                  <Row style={{ background: "#EDF2F9" }}>
                    <Col>
                      <p><strong>Lote Nro:</strong> {this.state.selectedRecibo.id}</p>
                      <p><strong>Descripción: </strong>{this.state.selectedRecibo.descripcion}</p>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.handleDialogEliminarLote(false)} variant="contained">Cancelar</Button>
                  <Button onClick={this.handleEliminarLote} color="primary" autoFocus>
                    Aceptar
                  </Button>
                </Modal.Footer>
              </Container>
            </Modal>

            <MensajeFlash
              mensaje={this.state.mensajeFlash}
              severity={this.state.mensajeFlashSeverity}
              open={this.state.mostrarMensajeFlash}
              handleClose={this.handleCloseMensajeFlash} />

            <div className="sectionHeader">
              <h1 className="pageTitle"> Lotes Para Publicar </h1>
            </div>
            <Navbar
              menuActual={window.location.hash}
              seccionActual={'lotes'}
            />
            <div className="contentPage card" style={{ padding: "0px" }}>
              <MaterialTable
                title="Lotes Para Publicar"
                style={{ borderRadius: ".5rem" }}
                tableRef={this.tableRef}
                columns={[
                  {
                    title: "Lote", field: "numero", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Fecha de importación", field: "fechaImportacion", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Descripción", field: "descripcion", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Cant. Documentos", field: "cantDocumentos", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Tipo de documento", field: "tipoDocumento", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Estado", field: "estado", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Estado MTESS", field: "estadoMTESS", type: "boolean", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                ]}
                data={(query) =>

                  new Promise((resolve, reject) => {
                    this.props.getLotes(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.lotesData.lotes,
                        page: this.props.lotesData.page,
                        totalCount: this.props.lotesData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  rowData => (
                    {
                      icon: () => <Link to={{pathname: '/lote/recibos/publicar/'+rowData.id, state: { rowData, estado: 'publicar' } }} style={{ textDecoration: 'none' }}><img src={visibility} /></Link>,
                      tooltip: 'Ver',
                      onClick: (rowData) => {
                      }
                    }),
                    {
                      icon: () => <img src={deleteIcon} />,
                      tooltip: 'Eliminar',
                      onClick: (event, rowData) => {
                        ;
                        this.setState({
                          dialogTitle: "Eliminar lote",
                          selectedRecibo: rowData,
                          dialogType: "edit",
                          submitText: "Editar",
                          disabledInput: false
                        }, () => {
                          this.handleDialogEliminarLote(true);
                        });
                      }
                    }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold"
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getLotes: (page, size, search) => dispatch(getLotes(page, size, search)),
});
const mapStateToProps = (state) => ({
  lotesData: state.lotesData,
});

export default connect(mapStateToProps, mapDispatchToProps)(LotesPublicar);

import React from "react";
import {firmarRRHH,firmarRL,firmaEmpleado} from '../recibos/RecibosService';
import { fabric } from "fabric";
import $ from "jquery";
import {EFIRMA, urlImageSign, firmaColaboradorApariencia} from "../../constans/constants";
import Resizer from "react-image-file-resizer";
import { Button, Container, Row, Col, Modal,Form } from "react-bootstrap";
import {inicializar,joinDetails,reload,get,create,remove,stringify} from "./AparienciaServicios"
import pdfdefault from '../../recibo-ejemplo.pdf'


class AparienciaFirma extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoFirma: [],
      idTipoFirma: "",
      tipoAparienciaEmpresa: [],
      tipoAparienciaEmpleado: [],
      idTipoAparienciaEmpresa: 1,
      idTipoAparienciaEmpleado: 1,
      tipoDetalleEmpresa: [],
      idTipoDetalleEmpresa: "",
      tipoDetalleEmpleado: [],
      idTipoDetalleEmpleado: "",
      usuarioDetalle: [],
      idUsuarioDetalle: "",
      empresaDetalle: [],
      idEmpresaDetalle: "",
      fileImagenEmpresa: null,
      urlImagenEmpresa: urlImageSign,
      urlImagenEmpresaMostrar: urlImageSign,
      fileImagenEmpleado: null,
      urlImagenEmpleado: urlImageSign,
      urlImagenEmpleadoMostrar: urlImageSign,
      urlImageFirmaColaborador: firmaColaboradorApariencia,
      urlImageFirmaColaboradorMostrar: firmaColaboradorApariencia,
      firmasSeleccionadas: [],
      filePDF: null,
      urlPDF: null,
      containerHeight: 730,
      pageNumber: null,
      detalleTipoEmpresaSelect: [],
      stampEmpresa: { type: "png" },
      configEmpresa:{},
      configEmpleado:{},
      base64StampEmpresa: urlImageSign.replace(
        /^data:image\/(png|jpg|jpeg);base64,/,
        ""
      ),
      base64StampEmpleado: urlImageSign.replace(
        /^data:image\/(png|jpg|jpeg);base64,/,
        ""
      ),
      stampEmpleado: { type: "png" },
      signEmpresa: {
        id: 1,
        name: "Empresa",
        page: 1,
        color: "#0067a6",
        detailType: "",
        details: "",
        stamp: "",
        freeText: "",
        appearance: "",
      },
      signEmpleado: {
        id: 2,
        name: "Empleado",
        page: 1,
        color: "#30E156",
        detailType: "",
        details: "",
        stamp: "",
        freeText: "",
        appearance: "",
      },
    };
    //this.generarpdf(pdfReferencia)
  }
  componentDidMount() {
    const tipo = [
      { id: 1, nombre: "Empresa" },
      { id: 2, nombre: "Empleado" },
    ];

    const aparienciaEmpresa = [
      { id: 1, name: "Sin Apariencia" },
      { id: 2, name: "Apariencia Estandar" },
    ];
    const detallesEmpresa = [
      { id: 1, name: "Imagen" },
      { id: 2, name: "Texto" },
      { id: 3, name: "Texto-Imagen" },
    ];
    const aparienciaEmpleado = [
      { id: 1, name: "Sin Apariencia" },
      { id: 2, name: "Apariencia Estandar" },
    ];
    const detallesEmpleado = [
      { id: 1, name: "Imagen" },
      { id: 2, name: "Texto" },
      { id: 3, name: "Texto-Imagen" },
      { id: 4, name: "Firma Colaborador"}
    ];
    const usuarioDetalle = [
      { id: "${ci}", name: "CI", check: false },
      { id: "${nombre_apellidos}", name: "Nombre y Apellidos", check: false },
      /*       { id: "${lugar}", name: "Lugar", check: false }, */
    ];
    const empresaDetalle = [
      { id: "${ruc}", name: "RUC", check: false },
      { id: "${nombre_empresa}", name: "Nombre", check: false },
      /*       { id: "${ubicacion}", name: "Ubicación", check: false }, */
    ];
    this.setState({ tipoFirma: tipo });
    this.setState({ tipoAparienciaEmpresa: aparienciaEmpresa });
    this.setState({ tipoAparienciaEmpleado: aparienciaEmpleado });
    this.setState({ tipoDetalleEmpresa: detallesEmpresa });
    this.setState({ tipoDetalleEmpleado: detallesEmpleado });
    this.setState({ usuarioDetalle: usuarioDetalle });
    this.setState({ empresaDetalle: empresaDetalle });
    this.getBase64FromUrl(urlImageSign).then((result)=>{
      this.setState({ base64StampEmpleado: result });
      this.setState({ base64StampEmpresa: result });
    });
    // myBase64 is the base64 string
    this.setState({ filePDF: pdfdefault})
    this.generarpdf(pdfdefault)
  }

  handleClose = () => {
    this.props.handleClose(false);
  };

   getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
      }
    });
  }

  /*-----------------------PDF-----------------------------------*/
  onDocumentComplete = this.onDocumentComplete.bind(this);
  onDocumentComplete(pages) {
    this.setState({ page: 1, pages });
  }
  onChangeFilePDF = (e) => {
    this.setState({ filePDF: e.target.files[0] });
    this.setState({ urlPDF: URL.createObjectURL(e.target.files[0]) });
    var url = URL.createObjectURL(e.target.files[0]);
    this.generarpdf(url);
  };

  generarpdf = (urlPD) => {
    var pdfjsLib = window["pdfjs-dist/build/pdf"];
    // The workerSrc property shall be specified.
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "/mirecibo/lib/build/pdf.worker.js";
    var canvasContainer = document.querySelector(".canvas-container");
    if(canvasContainer != null){
      var upperCanvas = document.querySelector(".upper-canvas");
      var canvasContainer = upperCanvas.parentNode;
      canvasContainer.removeChild(upperCanvas);
    }
    // Asynchronous download of PDF
    var loadingTask = pdfjsLib.getDocument(urlPD);
    loadingTask.promise.then(
      function (pdf) {
        // Fetch the first page
        var pageNumber = 1;
        pdf.getPage(pageNumber).then(function (page) {
          var scale = 1;
          var viewport = page.getViewport({ scale: scale });
          // Prepare canvas using PDF page dimensions
          var canvas = document.getElementById("the-canvas");
          var context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          // Render PDF page into canvas context
          var renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          var renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            inicializar(canvas);
          });
        });
      },
      function (reason) {
        console.error(reason);
      }
    );
  };
  /*---------------Fin PDF---------------------*/

  /*---------------Imagen----------------------*/
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  /*------------------Fin Imagen-----------------*/

  /*---------------Empresa---------------------*/
  handeCheckEmpresa = (e) => {
    var detallesTextAux = [];
    var datosActualizar = this.state.empresaDetalle;
    datosActualizar.forEach((detalle) => {
      if (e.target.value == detalle.id) {
        detalle.check = e.target.checked;
      }
      if (detalle.check) {
        detallesTextAux.push(detalle);
      }
    });
    this.actualizarCanvasEmpresa(detallesTextAux);
    this.setState({ empresaDetalle: datosActualizar });
  };

  actualizarCanvasEmpresa = (text) => {
    var urlImage = undefined;
    if (
      this.state.idTipoDetalleEmpresa == 1 ||
      this.state.idTipoDetalleEmpresa == 3
    ) {
      urlImage = this.state.urlImagenEmpresa;
    }
    var config = {
      id: 1,
      borderColor: "#0067a6",
      text: joinDetails("", text),
      stampUrl: urlImage,
    };
    this.setState({configEmpresa:config})
    reload(config);
    var typeDetails = this.state.tipoDetalleEmpresa.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpresa
    );
    var details = this.state.empresaDetalle.filter(
      (detalle) => detalle.check == true
    );
    this.setState({
      signEmpresa: {
        id: 1,
        page: 1,
        name: "Empresa",
        color: "#0067a6",
        detailType: typeDetails,
        details: details,
        stamp: urlImage,
        appearance: get(1),
      },
    });
  };

  handleUploadLogoEmpresa = (event) => {
    event.preventDefault();
    this.setState({ fileImagenEmpresa: event.target.files[0] });
    this.setState({
      urlImagenEmpresa: URL.createObjectURL(event.target.files[0]),
      stampEmpresa: event.target.files[0],
    });
    this.asignarBase64Empresa(event.target.files[0]);
    var urlImage = URL.createObjectURL(event.target.files[0]);
    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        250,
        200,
        "PNG",
        0,
        0,
        (uri) => {
          this.setState({
            urlImagenEmpresaMostrar: URL.createObjectURL(uri),
          });
        },
        "file"
      );
    } catch (error) {}
    this.createCanvasImageEmpresa(urlImage);
  };

  createCanvasImageEmpresa(urlImageSign) {
    var config = {
      id: 1,
      stampUrl: urlImageSign,
      borderColor: "#0067a6",
    };
    this.setState({configEmpresa:config})
    var typeDetails = this.state.tipoDetalleEmpresa.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpresa
    );
    var details = this.state.empresaDetalle.filter(
      (detalle) => detalle.check == true
    );

    create(config);
    this.setState({
      signEmpresa: {
        id: 1,
        page: 1,
        name: "Empresa",
        color: "#0067a6",
        detailType: typeDetails,
        details: details,
        stamp: urlImageSign,
        appearance: get(1),
      },
    });
  }

  createCanvasTextEmpresa(text) {
    var config = {
      id: 1,
      stampUrl: undefined,
      borderColor: "#0067a6",
      text: joinDetails("", text),
    };
    this.setState({configEmpresa:config})
    var typeDetails = this.state.tipoDetalleEmpresa.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpresa
    );
    var details = this.state.empresaDetalle.filter(
      (detalle) => detalle.check == true
    );
    create(config);
    this.setState({
      signEmpresa: {
        id: 1,
        page: 1,
        name: "Empresa",
        color: "#0067a6",
        detailType: typeDetails,
        details: details,
        stamp: undefined,
        appearance: get(1),
      },
    });
  }

  clearCheckEmpresa = () => {
    var datosActualizar = this.state.empresaDetalle;
    datosActualizar.forEach((detalle) => {
      detalle.check = false;
    });
    this.setState({ empresaDetalle: datosActualizar });
  };

  asignarBase64Empresa = (file) => {
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        this.setState({
          base64StampEmpresa: result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /*--------------------Fin Empresa------------------ */

  /*--------------------Colaborador-----------------*/
  handeCheckEmpleado = (e) => {
    var detallesTextAux = [];
    var datosActualizar = this.state.usuarioDetalle;
    datosActualizar.forEach((detalle) => {
      if (e.target.value == detalle.id) {
        detalle.check = e.target.checked;
      }
      if (detalle.check) {
        detallesTextAux.push(detalle);
      }
    });
    this.actualizarCanvasEmpleado(detallesTextAux);
    this.setState({ usuarioDetalle: datosActualizar });
  };

  actualizarCanvasEmpleado = (text) => {
    var urlImage = undefined;
    if (
      this.state.idTipoDetalleEmpleado == 1 ||
      this.state.idTipoDetalleEmpleado == 3
    ) {
      urlImage = this.state.urlImagenEmpleado;
    }
    if(this.state.idTipoDetalleEmpleado == 4){
      urlImage = this.state.urlImageFirmaColaborador
    }
    var config = {
      id: 2,
      borderColor: "#30E156",
      text: joinDetails("", text),
      stampUrl: urlImage,
    };
    this.setState({configEmpleado:config})
    reload(config);
    var typeDetails = this.state.tipoDetalleEmpleado.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpleado
    );
    var details = this.state.usuarioDetalle.filter(
      (detalle) => detalle.check == true
    );
    this.setState({
      signEmpleado: {
        id: 2,
        page: 1,
        name: "Empleado",
        color: "#30E156",
        detailType: typeDetails,
        details: details,
        stamp: urlImage,
        appearance: get(2),
      },
    });
  };

  handleUploadLogoEmpleado = (event) => {
    event.preventDefault();
    this.setState({ fileImagenEmpleado: event.target.files[0] });
    this.setState({
      urlImagenEmpleado: URL.createObjectURL(event.target.files[0]),
      stampEmpleado: event.target.files[0],
    });
    this.asignarBase64Empleado(event.target.files[0]);
    var urlImage = URL.createObjectURL(event.target.files[0]);
    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        250,
        200,
        "PNG",
        0,
        0,
        (uri) => {
          this.setState({
            urlImagenEmpleadoMostrar: URL.createObjectURL(uri),
          });
        },
        "file"
      );
    } catch (error) {}
    this.createCanvasImageEmpleado(urlImage);
  };

  createCanvasImageEmpleado(urlImageSign) {
    var config = {
      id: 2,
      stampUrl: urlImageSign,
      borderColor: "#30E156",
    };
    this.setState({configEmpleado:config})
    var typeDetails = this.state.tipoDetalleEmpleado.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpleado
    );
    var details = this.state.usuarioDetalle.filter(
      (detalle) => detalle.check == true
    );

    create(config);
    this.setState({
      signEmpleado: {
        id: 2,
        page: 1,
        name: "Empleado",
        color: "#30E156",
        detailType: typeDetails,
        details: details,
        stamp: urlImageSign,
        appearance: get(2),
      },
    });
  }

  createCanvasTextEmpleado(text) {
    var config = {
      id: 2,
      stampUrl: undefined,
      borderColor: "#30E156",
      text: joinDetails("", text),
    };
    this.setState({configEmpleado:config})
    var typeDetails = this.state.tipoDetalleEmpleado.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpleado
    );
    var details = this.state.usuarioDetalle.filter(
      (detalle) => detalle.check == true
    );
    create(config);
    this.setState({
      signEmpleado: {
        id: 2,
        page: 1,
        name: "Empleado",
        color: "#30E156",
        detailType: typeDetails,
        details: details,
        stamp: undefined,
        appearance: get(2),
      },
    });
  }

  clearCheckEmpleado = () => {
    var datosActualizar = this.state.usuarioDetalle;
    datosActualizar.forEach((detalle) => {
      detalle.check = false;
    });
    this.setState({ usuarioDetalle: datosActualizar });
  };

  asignarBase64Empleado = (file) => {
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        this.setState({
          base64StampEmpleado: result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /*-------------------Fin Colaborador----------------------------*/

  handleInput = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    this.setState({
      [name]: val,
    });
    if (
      (name == "idTipoDetalleEmpresa" && val == 1) ||
      (name == "idTipoDetalleEmpresa" && val == 3)
    ) {
      this.createCanvasImageEmpresa(this.state.urlImagenEmpresa);
    }
    if (name == "idTipoDetalleEmpresa" && val == 2) {
      this.createCanvasTextEmpresa([
        {
          id: "${mirecibo}",
          name: "mirecibo",
          check: true,
        },
      ]);
    }
    if (name == "idTipoDetalleEmpleado" && val == 2) {
      this.createCanvasTextEmpleado([
        {
          id: "${mirecibo}",
          name: "mirecibo",
          check: true,
        },
      ]);
    }
    if (
      (name == "idTipoDetalleEmpleado" && val == 1) ||
      (name == "idTipoDetalleEmpleado" && val == 3)
    ) {
      this.createCanvasImageEmpleado(this.state.urlImagenEmpleado);
    }
    if (
      (name == "idTipoDetalleEmpleado" && val == 4)) {
      this.createCanvasImageEmpleado(this.state.urlImageFirmaColaborador);
    }
    if (name == "idTipoAparienciaEmpresa" && val == 1) {
      remove(1);
      this.clearCheckEmpresa();
      this.setState({ idTipoDetalleEmpresa: "" });
    }
    if (name == "idTipoAparienciaEmpleado" && val == 1) {
      remove(2);
      this.clearCheckEmpleado();
      this.setState({ idTipoDetalleEmpleado: "" });
    }
  };
  guardar = async () => {
    await this.reloadCanvas
    if(this.state.idTipoDetalleEmpresa == 2){
      this.setState((prevState) => ({
        ...prevState,
        signEmpresa: {
          ...prevState.signEmpresa,
          appearance: get(1),
        },
      }));
    }
    if(this.state.idTipoDetalleEmpleado == 2){
      this.setState((prevState) => ({
        ...prevState,
        signEmpleado: {
          ...prevState.signEmpleado,
          appearance: get(2),
        },
      }));
    }
    var typeDetailEmpresa = this.state.tipoDetalleEmpresa.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpresa
    );
    var jsonEmpresa = stringify(
      typeDetailEmpresa,
      this.state.signEmpresa,
      this.state.stampEmpresa,
      this.state.base64StampEmpresa
    );
    var typeDetailEmpleado = this.state.tipoDetalleEmpleado.find(
      (tipo) => tipo.id == this.state.idTipoDetalleEmpleado
    );

    var jsonEmpleado = stringify(
      typeDetailEmpleado,
      this.state.signEmpleado,
      this.state.stampEmpleado,
      this.state.base64StampEmpleado
    );
    this.props.firmaEmpresa(jsonEmpresa);
    this.props.firmaEmpleado(jsonEmpleado);
    this.handleClose();
  };

  reloadCanvas(){
    reload(this.state.configEmpresa)
    reload(this.state.configEmpleado)
  }
  verificarPdfReferencia = () => {
    if (
      (!this.state.filePDF && this.state.idTipoAparienciaEmpresa == 2) ||
      (!this.state.filePDF && this.state.idTipoAparienciaEmpleado == 2)
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        <div className="modal-content">
      <Modal.Header  className="modal-header" closeButton>
        <Modal.Title style={{ color: "#0c56d0" }}> Configurar Apariencia de Firma </Modal.Title>
      </Modal.Header>
      <Modal.Body classname="modal-body">
      <Form onSubmit={this.handleSubmit}>
        <Row>
        <Col xs={6} md={4}>
            <Form.Group controlId="formFileSm" className="mb-3">
                <div className="fileInput fileInput-referencia"  >
                  <p className="form-title"> <strong> SELECCIONAR ARCHIVO DE REFERENCIA </strong></p>
                  <input className="inputFile" type="file" size="sm" onChange={this.onChangeFilePDF} accept=".pdf"/>
                    <label
                    name="uploadedFileName"
                    style={{ marginLeft: "20px", marginTop: "5px", color:"#df170d" }}
                  >
                    {this.state.filePDF ? this.state.filePDF.name : " "}
                    {this.verificarPdfReferencia()
                      ? "Debe seleccionar un documento PDF de referencia "
                      : " "}
                  </label>
                </div>
            </Form.Group>
                {
                  this.props.isFirmaEmpresa &&
                  <div className="section-empresa">
                    <p className="form-title"> <strong> EMPRESA </strong> </p>
                    <Form.Group className="form-group md-input">
                      <Form.Select className="form-control md-select selectApariencia"
                          name="idTipoAparienciaEmpresa"
                          onChange={this.handleInput}
                          value={this.state.idTipoAparienciaEmpresa}>
                            {this.state.tipoAparienciaEmpresa.map((elemento) => (
                            <option key={elemento.id} value={elemento.id}>
                              {elemento.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Label className="md-label"> Tipo de Apariencia: </Form.Label>
                      </Form.Group>
                      {this.state.idTipoAparienciaEmpresa == 2 &&
                      this.state.filePDF ?(<Form.Group className="form-group md-input form-aparienciaEmpresa detallesApariencia">
                      <Form.Select className="form-control md-select selectDetalle" labelId="formatoimportlabelid"
                            name="idTipoDetalleEmpresa"
                            label="Formato de importacion"
                            onChange={this.handleInput}
                            value={this.state.idTipoDetalleEmpresa}>
                            <option>
                                
                              </option>
                              {this.state.tipoDetalleEmpresa.map((elemento) => (
                              <option key={elemento.id} value={elemento.id}>
                                {elemento.name}
                              </option>
                            ))}
                      </Form.Select>
                      <Form.Label className="md-label"> Detalles de apariencia de firma <span>*</span> </Form.Label>
                      </Form.Group>): null}

                      {(this.state.idTipoDetalleEmpresa == 2 &&
                        this.state.idTipoAparienciaEmpresa == 2) ||
                      (this.state.idTipoDetalleEmpresa == 3 &&
                        this.state.idTipoAparienciaEmpresa == 2) ? (
                          <Form.Group className="form-check-inline">
                          {this.state.empresaDetalle.map((elemento) => (
                            <div className="form-check">
                                <Form.Check
                                  onClick={this.handeCheckEmpresa}
                                  key={elemento.id}
                                  checked={elemento.check}
                                  value={elemento.id}
                                  label={elemento.name}
                                />
                                </div>
                          ))}
                        </Form.Group>
                      ) : null}   
                      {this.state.idTipoAparienciaEmpresa == 2 ? (<Form.Group className="fileInput">
                      <input className="inputFile" type="file" id="formFile" accept=".png" onChange={this.handleUploadLogoEmpresa}/> 
                    </Form.Group>) : null}
                  </div>
                }
                {
                this.props.isFirmaColaborador &&
                  <div className="section-colaborador">
                    <p className="form-title"> <strong> COLABORADOR </strong> </p>
                    <Form.Group className="form-group md-input form-aparienciaColaborador">
                    <Form.Select className="form-control md-select selectApariencia"
                          name="idTipoAparienciaEmpleado"
                          onChange={this.handleInput}
                          value={this.state.idTipoAparienciaEmpleado}>
                          {this.state.tipoAparienciaEmpleado.map((elemento) => (
                            <option key={elemento.id} value={elemento.id}>
                              {elemento.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Label className="md-label"> Tipo de Apariencia:  <span>*</span> </Form.Label>
                    </Form.Group>
                    {this.state.idTipoAparienciaEmpleado == 2 &&
                      this.state.filePDF ? (<Form.Group className="form-group md-input form-aparienciaColaborador detallesApariencia">
                      <Form.Select className="form-control md-select selectDetalle"
                            name="idTipoDetalleEmpleado"
                            onChange={this.handleInput}
                            value={this.state.idTipoDetalleEmpleado}>
                                <option>
                                
                                </option>
                            {this.state.tipoDetalleEmpleado.map((elemento) => (
                              <option key={elemento.id} value={elemento.id}>
                                {elemento.name}
                              </option>
                            ))}
                      </Form.Select>
                      <Form.Label className="md-label"> Detalles de apariencia de firma <span>*</span> </Form.Label>
                    </Form.Group>):null}
      
                    {(this.state.idTipoDetalleEmpleado == 2 &&
                        this.state.idTipoAparienciaEmpleado == 2) ||
                      (this.state.idTipoDetalleEmpleado == 3 &&
                        this.state.idTipoAparienciaEmpleado == 2) ? (<Form.Group class="form-check-inline" id="check-empresa">
                    {this.state.usuarioDetalle.map((elemento) => (
                      <div class="form-check">
                                <Form.Check
                                  onClick={this.handeCheckEmpleado}
                                  key={elemento.id}
                                  checked={elemento.check}
                                  value={elemento.id}
                                  label={elemento.name}
                                />
                                </div>
                          ))}
                    </Form.Group>):null}
                    {this.state.idTipoAparienciaEmpleado == 2 ? (<Form.Group className="fileInput">
                      <input className="inputFile" type="file" id="formFile" accept=".png" onChange={this.handleUploadLogoEmpleado}/> 
                    </Form.Group>) : null}
                  </div>
                }
                </Col>
                <Col xs={12} md={8}>
                  <div className="pdf-background" style={{justifyContent:'center'}}>
                  <div className="pdf-file">
                    <canvas
                      id="the-canvas"
                      style={{ height: this.state.containerHeight }}
                    ></canvas>
                  </div>
                </div>
              </Col>
        </Row>
      </Form>
      </Modal.Body>
       <Modal.Footer classname="modal-footer">
      <Button variant="contained" color="primary" onClick={this.props.handleClose}> CANCELAR </Button>
      <Button className="btn btn-primary" onClick={this.guardar}> GUARDAR </Button>
      </Modal.Footer> 
      </div>
      </div>
    );
  }
}

export default AparienciaFirma;
